import React from 'react'
import Banner from '../Componets/Blogsingle/Banner'
import NewNavBar from '../Componets/Homepage/NewNavBar'

function Blogsingle() {
  return (
    <div>
        <NewNavBar/>
      <Banner/>
    </div>
  )
}

export default Blogsingle
