import React from 'react'
import NewNavBar from '../Componets/Homepage/NewNavBar'
import Banner from '../Componets/NewSingle/Banner'
function NewsSingel() {
    return (
        <div>
            <NewNavBar />
            <Banner />
        </div>
    )
}

export default NewsSingel
