import React, { useEffect, useRef, useState } from 'react'
import { FiArrowUpRight } from 'react-icons/fi';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { GoDotFill } from "react-icons/go"
import { Link } from 'react-router-dom';
import { FaBarsStaggered } from 'react-icons/fa6';
import { FaCaretDown } from 'react-icons/fa';
import { MdOutlineBrightness1 } from 'react-icons/md';
import { IoIosCloseCircleOutline } from 'react-icons/io';

function NavBar() {
  const [isInsightsOpen, setInsightsOpen] = useState("");
  const [sideBar, setsideBar] = useState(false)
  const [dropdown, setDropdown] = useState("")
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setInsightsOpen(""); // Close the dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener to handle outside clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div style={{zIndex:"99"}} className='d-flex justify-content-center '>

      <div className='navbar mt-4'>
        <div className='nav_logo'>
          <Link to={'/'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="180" height="22" viewBox="0 0 180 22" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.164062H3.94237L16.8791 14.6804V0.164062H21.0575V21.1686H17.4928L4.17844 6.2211V21.1686H0V0.164062Z" fill="#00293E" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.9308 21.1688H42.2331L34.9621 5.00988L27.7147 21.1688H23.1113L33.1679 0H36.8979L46.9308 21.1688Z" fill="#00293E" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.5444 17.905L50.0232 15.3384C52.2658 17.0426 54.5321 18.0077 57.4358 18.0077C59.9854 18.0077 61.6142 16.981 61.6142 15.4411V15.3795C61.6142 13.9217 60.67 13.121 56.3027 12.2586C51.298 11.2115 48.4651 9.91791 48.4651 6.13997V6.07837C48.4651 2.56734 51.8173 0.144531 56.4915 0.144531C59.9145 0.144531 62.6293 1.04795 65.0373 2.71107L62.8182 5.42133C60.6936 4.04567 58.5925 3.30651 56.4443 3.30651C54.0128 3.30651 52.62 4.39472 52.62 5.72932V5.79092C52.62 7.37191 53.7059 8.07 58.1912 9.01449C63.1723 10.0616 65.7455 11.6221 65.7455 15.0099V15.0715C65.7455 18.8905 62.2752 21.1696 57.3414 21.1696C53.7531 21.1696 50.3773 20.0814 47.5444 17.905Z" fill="#00293E" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.2435 0.144531H84.2539L77.0537 10.7392L84.1594 21.1696H88.2435L81.3502 10.7392L88.2435 0.144531Z" fill="#1E96D3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M82.1054 0.144531H75.7787L68.3188 10.7392L75.9439 21.1696H82.1054L75.2121 10.7392L82.1054 0.144531Z" fill="#1E96D3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M68.3188 7.31031V0.144531H73.6776L68.3188 7.4951V7.31031Z" fill="#1E96D3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M68.3188 14.0237V21.1689H73.6776L68.3188 13.8184V14.0237Z" fill="#1E96D3" />
            <path d="M101.436 21.5393C97.5878 21.5393 94.6369 20.4306 91.7568 18.1925L92.9136 17.0222C95.5576 19.137 97.9891 20.1226 101.53 20.1226C105.118 20.1226 107.597 18.3568 107.597 15.9751V15.9134C107.597 13.7165 106.252 12.423 100.798 11.4785C95.0382 10.4724 92.7011 8.76821 92.7011 5.60623V5.54463C92.7011 2.44425 95.9589 0.0625 100.421 0.0625C103.914 0.0625 106.204 0.904329 108.659 2.58798L107.526 3.81992C105.26 2.11574 102.994 1.4587 100.373 1.4587C96.8324 1.4587 94.5189 3.22448 94.5189 5.40091V5.4625C94.5189 7.65946 95.8172 9.0146 101.507 10.0207C107.054 11.0062 109.391 12.7309 109.391 15.7697V15.8313C109.391 19.2192 106.063 21.5393 101.436 21.5393Z" fill="#00293E" />
            <path d="M124.854 21.6C117.583 21.6 112.814 16.5696 112.814 10.8616V10.8C112.814 5.11255 117.654 0 124.925 0C132.196 0 136.964 5.03042 136.964 10.7384C136.988 10.7589 136.988 10.7589 136.964 10.8C136.964 16.508 132.125 21.6 124.854 21.6ZM135.123 10.8C135.123 5.64639 130.803 1.41673 124.878 1.41673C118.952 1.41673 114.703 5.58479 114.703 10.7589V10.8205C114.703 15.9741 119.023 20.2038 124.948 20.2038C130.874 20.2038 135.123 16.0357 135.123 10.8821V10.8Z" fill="#00293E" />
            <path d="M141.875 1.8064V10.3684H155.449V11.8056H141.875V21.2505H140.081V0.369141H156.983V1.8064H141.875Z" fill="#00293E" />
            <path d="M171.643 1.8064V21.2505H169.849V1.8064H161.492V0.369141H180V1.8064H171.643Z" fill="#00293E" />
          </svg>
          </Link>
        </div>
        <div className='li'>
          <ul>
            <li onClick={() => {
              if (isInsightsOpen === "" || isInsightsOpen !== "insignts") {

                setInsightsOpen("service")
              }
              else {

                setInsightsOpen("")
              }
            }}>
              SERVICES <span><RiArrowDropDownLine /></span>
              {isInsightsOpen === "service" && (
                <ul ref={dropdownRef} className='dropdown-menu' style={{ marginTop: ".4rem" }}>

                  <div className='services-menu'>
                    <div className='row m-0'>
                      <div className='col-lg-3 p-0'>
                        <div className='service d-flex justify-content-center px-4 flex-column h-100'>
                          <h3>Building Transformative Solutions for Seamless Digital Growth</h3>
                          <p className='pt-2'>Nasksoft offers end-to-end digital solutions from development and e-commerce to digital transformation, partnering with clients to enhance efficiency and achieve lasting results.</p>
                        </div>
                      </div>
                      <div className='col-lg-9'>
                        <div className='service-left'>
                          <div className='row'>
                            <div className='col-lg-4 col-md-4 '>
                              <div className='pading'>
                                <Link style={{ textDecoration: 'none' }} className='d-flex' to='/development-services'>
                                  <div className='devl'>
                                    <div className='Development'>

                                      <div className="circul"><FiArrowUpRight className='nav_arrow' /></div>
                                      <h3>Development Services</h3>
                                    </div>
                                    <ul className='p-0 d-flex flex-column'>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Web Development</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Mobile App Development</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Custom Software Development</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Shopify Development</li>
                                    </ul>
                                  </div>
                                </Link>



                              </div>
                            </div>
                            <div className='col-lg-4 col-md-4 '>
                              <div className='pading'>
                                <Link style={{ textDecoration: 'none' }} className='d-flex' to='/design-services'>
                                  <div className='devl'>
                                    <div className='Development'>

                                      <div className="circul"><FiArrowUpRight className='nav_arrow' /></div>
                                      <h3>Digital Design Services</h3>
                                    </div>
                                    <ul className='p-0 d-flex flex-column'>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Product Design</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                       UI/UX Design</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Mobile App Design</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Brand Design</li>
                                    </ul>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div className='col-lg-4 col-md-4 '>
                              <div className='pading'>
                                <Link style={{ textDecoration: 'none' }} className='d-flex' to='/seo-services'>
                                  <div className='devl'>
                                    <div className='Development'>

                                      <div className="circul"><FiArrowUpRight className='nav_arrow' /></div>
                                      <h3>SEO & Digital Marketing</h3>
                                    </div>
                                    <ul className='p-0 d-flex flex-column'>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                      Search Engine Optimization</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                      Social Media Marketing</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                      Email Marketing</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                      Content Strategy & Optimization</li>
                                    </ul>

                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div className='col-lg-4 col-md-4 '>
                              <div className='pading'>


                                <Link style={{ textDecoration: 'none' }} className='d-flex' to='/ecommerce-services'>
                                  <div className='devl'>
                                    <div className='Development'>

                                      <div className="circul"><FiArrowUpRight className='nav_arrow' /></div>
                                      <h3>E-commerce Solutions</h3>
                                    </div>
                                    <ul className='p-0 d-flex flex-column'>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                      E-commerce Platform Development</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Amazon & eBay Solutions</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                      Market Place Development</li>
                                    </ul>

                                  </div>
                                </Link>

                              </div>
                            </div>



                            <div className='col-lg-4 col-md-4 '>
                              <div className='pading'>


                                <Link style={{ textDecoration: 'none' }} className='d-flex' to='/cloud-services'>
                                  <div className='devl'>
                                    <div className='Development '>

                                      <div className="circul"><FiArrowUpRight className='nav_arrow' /></div>
                                      <h3>Cloud & DevOps Services
                                      </h3>
                                    </div>
                                    <ul className='p-0 d-flex flex-column'>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                      Cloud Migration & Management</li>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                      DevOps Services</li>
                                    </ul>

                                  </div>
                                </Link>

                              </div>
                            </div>
                            <div className='col-lg-4 col-md-4 '>
                              <div className='pading'>

                                <Link style={{ textDecoration: 'none' }} className='d-flex' to='/quailty-services'>

                                  <div className='devl'>
                                    <div className='Development'>

                                      <div className="circul"><FiArrowUpRight className='nav_arrow' /></div>
                                      <h3>Consulting & Digital Transformation</h3>
                                    </div>
                                    <ul className='p-0 d-flex flex-column'>
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        QA Testing & Automation</li>
                                        <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Digital Transformation</li>
                                     
                                      <li className='lis p-0'><GoDotFill className='dot_col' />
                                        IT Consulting Services</li>
                                        <li className='lis p-0'><GoDotFill className='dot_col' />
                                        Maintenance & Support Services</li>
                                 
                                    </ul>

                                  </div>
                                </Link>

                              </div>
                            </div>
                  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </ul>
              )}
            </li>
            <Link style={{ textDecoration: 'none' }} className='d-flex' to='/about-us'>
              <li>ABOUT</li>
            </Link>
            <Link style={{ textDecoration: 'none' }} className='d-flex'  to={'/career'}>
            <li>CAREERS</li>
            </Link>
            <li onClick={() => {
              if (isInsightsOpen === "" || isInsightsOpen !== "intsight") {

                setInsightsOpen("intsight")
              }
              else {

                setInsightsOpen("")
              }
            }}>
              INSIGHTS <span><RiArrowDropDownLine /></span>
              {isInsightsOpen === "intsight" && (

                <ul ref={dropdownRef} className='dropdown-menu w-100' style={{ marginTop: ".4rem" }}>

                  <div className='row w-100 m-0 p-0'>
                    <div className='col-3 p-0'>
                      <div className='insights-heading d-flex flex-column justify-content-center h-100 ps-3'>
                        <h3>Corporate Insights</h3>
                        <p>Latest Projects, Insights, and Tech Trends That Inspire Us</p>
                      </div>
                    </div>
                    <div className='col-9 p-0 d-flex pe-3'>
                      <div className='d-flex'>
                        <div className='case'>
                          <a href='#insight_box' style={{textDecoration:"none"}}>
                          <h5 className='d-flex'> <div className='arrow-tag'><FiArrowUpRight className='nav_arrow' /></div>Case studies</h5>
                          <p>Explore Featured Projects</p>
                          </a>
                        </div>

                        <div className='case'>
                          <Link style={{ textDecoration: 'none' }} to={"/news"}>
                            <h5 className='d-flex'> <div className='arrow-tag'><FiArrowUpRight className='nav_arrow' /></div>Trending Now</h5>
                            <p>News In the Spotlight</p>
                          </Link>
                        </div>

                        {/* <div className='case'>
                          <Link style={{ textDecoration: 'none' }} to={"/Blogs"}>
                            <h5 className='d-flex'> <div className='arrow-tag'><FiArrowUpRight className='nav_arrow' /></div>Blogs</h5>
                            <p>Our Latest Updated Blog Post</p>
                          </Link>
                        </div> */}



                       
                      </div>

                    </div>
                  </div>

                </ul>
              )}
            </li>
          </ul>
        </div>

        <div className='lets'>
          <Link to={'/contact-us'} style={{textDecoration:"none"}}>
          <p>Let's Work Together</p>
          </Link>
          <button onClick={() => setsideBar(true)} className='nav_btn'>
            <FaBarsStaggered />
          </button>
        </div>
      </div>
      <div style={{ left: `${sideBar ? "0%" : "-310px"}` }} className='side_bar'>
        <div className='d-flex justify-content-between align-items-center'>
          <Link to='/'>
          <svg xmlns="http://www.w3.org/2000/svg" width="180" height="22" viewBox="0 0 180 22" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.164062H3.94237L16.8791 14.6804V0.164062H21.0575V21.1686H17.4928L4.17844 6.2211V21.1686H0V0.164062Z" fill="#00293E" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.9308 21.1688H42.2331L34.9621 5.00988L27.7147 21.1688H23.1113L33.1679 0H36.8979L46.9308 21.1688Z" fill="#00293E" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.5444 17.905L50.0232 15.3384C52.2658 17.0426 54.5321 18.0077 57.4358 18.0077C59.9854 18.0077 61.6142 16.981 61.6142 15.4411V15.3795C61.6142 13.9217 60.67 13.121 56.3027 12.2586C51.298 11.2115 48.4651 9.91791 48.4651 6.13997V6.07837C48.4651 2.56734 51.8173 0.144531 56.4915 0.144531C59.9145 0.144531 62.6293 1.04795 65.0373 2.71107L62.8182 5.42133C60.6936 4.04567 58.5925 3.30651 56.4443 3.30651C54.0128 3.30651 52.62 4.39472 52.62 5.72932V5.79092C52.62 7.37191 53.7059 8.07 58.1912 9.01449C63.1723 10.0616 65.7455 11.6221 65.7455 15.0099V15.0715C65.7455 18.8905 62.2752 21.1696 57.3414 21.1696C53.7531 21.1696 50.3773 20.0814 47.5444 17.905Z" fill="#00293E" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.2435 0.144531H84.2539L77.0537 10.7392L84.1594 21.1696H88.2435L81.3502 10.7392L88.2435 0.144531Z" fill="#1E96D3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M82.1054 0.144531H75.7787L68.3188 10.7392L75.9439 21.1696H82.1054L75.2121 10.7392L82.1054 0.144531Z" fill="#1E96D3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M68.3188 7.31031V0.144531H73.6776L68.3188 7.4951V7.31031Z" fill="#1E96D3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M68.3188 14.0237V21.1689H73.6776L68.3188 13.8184V14.0237Z" fill="#1E96D3" />
            <path d="M101.436 21.5393C97.5878 21.5393 94.6369 20.4306 91.7568 18.1925L92.9136 17.0222C95.5576 19.137 97.9891 20.1226 101.53 20.1226C105.118 20.1226 107.597 18.3568 107.597 15.9751V15.9134C107.597 13.7165 106.252 12.423 100.798 11.4785C95.0382 10.4724 92.7011 8.76821 92.7011 5.60623V5.54463C92.7011 2.44425 95.9589 0.0625 100.421 0.0625C103.914 0.0625 106.204 0.904329 108.659 2.58798L107.526 3.81992C105.26 2.11574 102.994 1.4587 100.373 1.4587C96.8324 1.4587 94.5189 3.22448 94.5189 5.40091V5.4625C94.5189 7.65946 95.8172 9.0146 101.507 10.0207C107.054 11.0062 109.391 12.7309 109.391 15.7697V15.8313C109.391 19.2192 106.063 21.5393 101.436 21.5393Z" fill="#00293E" />
            <path d="M124.854 21.6C117.583 21.6 112.814 16.5696 112.814 10.8616V10.8C112.814 5.11255 117.654 0 124.925 0C132.196 0 136.964 5.03042 136.964 10.7384C136.988 10.7589 136.988 10.7589 136.964 10.8C136.964 16.508 132.125 21.6 124.854 21.6ZM135.123 10.8C135.123 5.64639 130.803 1.41673 124.878 1.41673C118.952 1.41673 114.703 5.58479 114.703 10.7589V10.8205C114.703 15.9741 119.023 20.2038 124.948 20.2038C130.874 20.2038 135.123 16.0357 135.123 10.8821V10.8Z" fill="#00293E" />
            <path d="M141.875 1.8064V10.3684H155.449V11.8056H141.875V21.2505H140.081V0.369141H156.983V1.8064H141.875Z" fill="#00293E" />
            <path d="M171.643 1.8064V21.2505H169.849V1.8064H161.492V0.369141H180V1.8064H171.643Z" fill="#00293E" />
          </svg>
          </Link>
          <IoIosCloseCircleOutline onClick={() => setsideBar(false)} style={{ fontSize: "25px", cursor: "pointer" }} />
        </div>
        <ul className='p-0 ul'>
          <li className='li'><div onClick={() => {
            if (dropdown === "service") {
              setDropdown("")
            }
            else {
              setDropdown("service")
            }
          }} className='w-100 d-flex justify-content-between align-items-center'>Services <FaCaretDown style={{ transform: `rotate(${dropdown === "service" ? "180" : "0"}deg)` }} className='p-0 m-0 arrow' /></div>
            {dropdown === "service" ? <div>
              <ul className="p-0">
                  <Link
                    style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
                    to="/development-services"
                  >
                    <li>
                      <MdOutlineBrightness1 className="p-0 m-0 me-2 dot" />
                      <div>Development Services</div>
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
                    to="/design-services"
                  >
                    <li>
                      <MdOutlineBrightness1 className="p-0 m-0 me-2 dot" />
                      <div>Digital Design Services</div>
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
                    to="/ecommerce-services"
                  >
                    <li>
                      <MdOutlineBrightness1 className="p-0 m-0 me-2 dot" />
                      <div>E-Com solutions</div>
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
                    to="/cloud-services"
                  >
                    <li>
                      <MdOutlineBrightness1 className="p-0 m-0 me-2 dot" />
                      <div>Cloud & DevOps</div>
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
                    to="/quailty-services"
                  >
                    <li>
                      <MdOutlineBrightness1 className="p-0 m-0 me-2 dot" />
                      <div>Consulting & Digital</div>
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
                    to="/seo-services"
                  >
                    <li>
                      <MdOutlineBrightness1 className="p-0 m-0 me-2 dot" />
                      <div>SEO & Digital Marketing Services</div>
                    </li>
                  </Link>
                </ul>
            </div> : ""}
          </li>
          <Link
            style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
            to="/about-us"
          >
            <li className="li">About</li>
          </Link>
          <Link style={{ textDecoration: "none", color: "black", cursor:'pointer' }} to="/career">
            <li className="li">CAREERS </li>
          </Link>
          <li className='li'><div onClick={() => {
            if (dropdown === "insignts") {
              setDropdown("")
            }
            else {
              setDropdown("insignts")
            }
          }} className='w-100 d-flex justify-content-between align-items-center'>insights <FaCaretDown style={{ transform: `rotate(${dropdown === "insignts" ? "180" : "0"}deg)` }} className='p-0 m-0 arrow' /></div>
            {dropdown === "insignts" ? <div>
              <ul className="p-0">
                  <Link
                    style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
                    to={"/#insight_box"}
                  >
                    <li>
                      <MdOutlineBrightness1 className="p-0 m-0 me-2 dot" />
                      <div>Case studies: Our Project</div>
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
                    to="/news"
                  >
                    <li>
                      <MdOutlineBrightness1 className="p-0 m-0 me-2 dot" />
                      <div>News</div>
                    </li>
                  </Link>
                  <Link
                    style={{ textDecoration: "none", color: "black", cursor:'pointer' }}
                    to="/Blogs"
                  >
                    {/* <li>
                      <MdOutlineBrightness1 className="p-0 m-0 me-2 dot" />
                      <div>Blog</div>
                    </li> */}
                  </Link>
                </ul>
            </div> : ""}</li>
        </ul>
      </div>
    </div>
  )
}

export default NavBar
