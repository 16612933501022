import React from 'react'
import img from '../../../Image/Career/No data-cuate 1.png'


function CareerNoData() {
    return (
        <>
            <div className="container-fluid">
                <div className="Careers_Data">
                    <div className="row m-0">
                        <div className="heading mb-4 m-0 p-0">
                            Current Job Openings
                        </div>
                        <div className="careers_para m-0 p-0">
                            Join our winning culture, where success and growth converge to create an inspiring environment!
                        </div>
                        <div className="career-imgbox">
                            <img src={img} alt="" />
                            <div className="career-imgtext">
                            No Job Found
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerNoData
