import React from 'react'
import { GoDot } from 'react-icons/go'
import dev from '../../../Image/development-services/Strategic consulting-cuate 2.svg'
import dev1 from '../../../Image/development-services/Developer activity-cuate(1) 2.svg'
import dev2 from '../../../Image/development-services/Frame 1300193247.svg'
function DevelopmentProcess() {
    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row m-0">
                    <div className="development-process">
                        <div className="col-lg-10 col-12 p-0">
                            <div className="heading">Our Development Process</div>
                            {/* Step 1 */}
                            <div className="row m-0 px-sm-5 px-4">
                                <div className="col-lg-5 col-sm-9 pb-lg-0 pb-4 col-12 p-0 mb-lg-0 mb-5 order-lg-1 order-2">
                                    <div className="development-textbox">
                                        <div className="label">Gathering Requirements</div>
                                        <div className="pad-box">
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">Requirements Definition</div>
                                            </div>
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">Analyzing the requirements</div>
                                            </div>
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">Documenting the process</div>
                                            </div>
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">Provide initial design</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-12 p-0 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 text-center order-lg-2 order-1">
                                    <div className="step-box">
                                        <div className="step"><div className="step-num">1</div></div>
                                    </div>
                                    <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                                </div>
                                <div className="col-lg-5 col-12 text-lg-start text-end px-0 pb-lg-5 pb-4 mb-2 order-3">
                                    <div className="development-imgbox">
                                        <img src={dev} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            {/* Step 2 */}
                            <div className="row mx-0 px-5">
                                <div className="col-lg-5 col-12 text-lg-start text-end px-0 mb-5 order-lg-1 order-3">
                                    <div className="development-imgbox">
                                        <img src={dev1} alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                                    <div className="step-box">
                                        <div className="step"><div className="step-num">2</div></div>
                                    </div>
                                    <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                                </div>
                                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-3 order-2">
                                    <div className="development-textbox">
                                        <div className="label">Development & Testing</div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Frontend & backend development</div>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Weekly Client Feedback (SCRUM)</div>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">QA Testing</div>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Deployment</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Step 3 */}
                            <div className="row m-0 my-4 px-5 pt-4">
                                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-1 order-2">
                                    <div className="development-textbox">
                                        <div className="label">Support & Maintenance</div>
                                        <div className="pad-box">
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">SLA Based Support</div>
                                            </div>
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">L3 and Production Support Services</div>
                                            </div>
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">Operational support where needed</div>
                                            </div>
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">On-going Support</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                                    <div className="step-box">
                                        <div className="step"><div className="step-num">3</div></div>
                                    </div>
                                    {/* Step line should be handled via CSS based on screen size */}
                                </div>
                                <div className="col-lg-5 col-12 text-lg-start text-end px-0 order-3">
                                    <div className="development-imgbox">
                                        <img src={dev2} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DevelopmentProcess;







