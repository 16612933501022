import React from 'react';
import shopify from '../../../Image/tools_icons/shopify.png';
import bootstrap from '../../../Image/tools_icons/boostrap.png';
import succuri from '../../../Image/tools_icons/sucuri.png';
import angular from '../../../Image/tools_icons/angular.png';
import reactIcon from '../../../Image/tools_icons/react-js.png'; 
import zendesk from '../../../Image/tools_icons/zendesk.png';
import aws from '../../../Image/tools_icons/aws.png';
import node from '../../../Image/tools_icons/node-js.png';
import vue from '../../../Image/tools_icons/vue-js.png';
import mailchimp from '../../../Image/tools_icons/mailchimp.png';
import figma from '../../../Image/tools_icons/figma.png';
import micro from '../../../Image/tools_icons/miro.png';
import clickup from '../../../Image/tools_icons/clickup.png';
import jira from '../../../Image/tools_icons/jira.png';

const tools = [
    { id: 1, name: 'Shopify', image: shopify },
    { id: 2, name: 'Vue.js', image: vue },
    { id: 3, name: 'Node.js', image: node },
    { id: 4, name: 'Bootstrap', image: bootstrap },
    { id: 5, name: 'React', image: reactIcon }, 
    { id: 6, name: 'Sucuri', image: succuri },
    { id: 7, name: 'aws', image: aws },
];

const secondtools = [
    { id: 8, name: 'Angular.js', image: angular },
    { id: 9, name: 'Zendesk', image: zendesk },
    { id: 10, name: 'Mailchimp', image: mailchimp },
    { id: 11, name: 'Figma', image: figma },
    { id: 12, name: 'Miro', image: micro },
    { id: 13, name: 'Clickup', image: clickup },
    { id: 14, name: 'Jira', image: jira },
]

const newTools = [
    { id: 1, name: 'Shopify', image: shopify },
    { id: 2, name: 'Vue.js', image: vue },
    { id: 3, name: 'Node.js', image: node },
    { id: 4, name: 'Bootstrap', image: bootstrap },
    { id: 5, name: 'React', image: reactIcon }, 
    { id: 6, name: 'Sucuri', image: succuri },
    { id: 7, name: 'aws', image: aws },
    { id: 8, name: 'Angular.js', image: angular },
    { id: 9, name: 'Zendesk', image: zendesk },
    { id: 10, name: 'Mailchimp', image: mailchimp },
    { id: 11, name: 'Figma', image: figma },
    { id: 12, name: 'Miro', image: micro },
    { id: 13, name: 'Clickup', image: clickup },
    { id: 14, name: 'Jira', image: jira },
]

function OurTools() {
    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="our-tools">
                    <div className="row m-0 p-0">
                        <div className="col-lg-4 col-12 p-0">
                            <div className="tool-text">
                                <div className="heading text-lg-start text-center">
                                Technologies & Tools Stack For Powering Development
                                </div>
                                <div className="tool-para text-lg-start text-center">
                                Collaborate with Nasksoft’s IT experts to create exceptional custom software 
                                using advanced frameworks and technologies.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12 ps-5 pe-lg-0 pe-5 pb-5 toolbox_original_box">
                            <div className="row px-3 mx-0 mt-4 pt-3 d-flex justify-content-between">
                                {tools.map((tool) => (
                                    <div key={tool.id} className="col-1 p-0">
                                        <div className="tools-iconbox">
                                            <div className="icon">
                                                <span className="icon-img">
                                                    <img src={tool.image} alt={tool.name} />
                                                </span>
                                                <div className="icon-name">{tool.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="row px-3 mx-0 mt-3 pt-2 d-flex justify-content-between">
                                {secondtools.map((tool) => (
                                    <div key={tool.id} className="col-1 p-0">
                                        <div className="tools-iconbox">
                                            <div className="icon">
                                                <span className="icon-img">
                                                    <img src={tool.image} alt={tool.name} />
                                                </span>
                                                <div className="icon-name">{tool.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="col-lg-8 col-12 ps-5 pe-lg-0 pe-5 pb-5 toolbox_non-original_box">
                            <div className="row px-3 mx-0 mt-4 pt-3 d-flex justify-content-center" style={{flexWrap:'wrap'}}>
                                {newTools.map((tool) => (
                                    <div key={tool.id} className="col-3 p-0 d-flex justify-content-center">
                                        <div className="tools-iconbox mb-3">
                                            <div className="icon">
                                                <span className="icon-img">
                                                    <img src={tool.image} alt={tool.name} />
                                                </span>
                                                <div className="icon-name">{tool.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-8 col-12 ps-4 pe-lg-0 pe-4 pb-5 toolbox_second_non-original_box">
                            <div className="row px-3 mx-0 mt-4 pt-3 d-flex justify-content-center" style={{flexWrap:'wrap'}}>
                                {newTools.map((tool) => (
                                    <div key={tool.id} className="col-4 p-0 d-flex justify-content-center">
                                        <div className="tools-iconbox mb-3">
                                            <div className="icon">
                                                <span className="icon-img">
                                                    <img src={tool.image} alt={tool.name} />
                                                </span>
                                                <div className="icon-name">{tool.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OurTools;
