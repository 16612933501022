import React from "react";
import { GoDot } from "react-icons/go";
import dev from "../../../Image/Design-services/Frame 1300193274.svg";
import dev1 from "../../../Image/Design-services/Frame 1300193273.svg";
import dev2 from "../../../Image/Design-services/Frame 1300193268.svg";
import dev3 from "../../../Image/Design-services/Frame 1300193267.svg";
import dev4 from "../../../Image/Design-services/Frame 1300193247.svg";

function DevelopmentProcess() {
  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row m-0">
          <div className="development-process">
            <div className="col-10 p-0">
              <div className="heading">Our Design Process</div>
              <div className="row m-0">
                <div className="col-lg-5 col-sm-9 pb-lg-0 pb-4 col-12 p-0 mb-lg-0 mb-5 order-lg-1 order-2">
                  <div className="development-textbox">
                    <div className="label p-0">Discovery (UX Research)</div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Research the business, audience & market.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Conduct interviews, surveys & competitor analysis.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Identify pain points, challenges & opportunities.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 col-12 p-0 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step">
                      <div className="step-num">1</div>
                    </div>
                  </div>
                  <div className="line d-lg-flex d-none">
                    <div className="step-line"></div>
                  </div>
                </div>
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 pb-lg-5 pb-4 mb-2 order-3">
                  <div className="development-imgbox">
                    <img src={dev} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="row mx-0 mt-5 ">
                <div className='col-lg-5 col-12 text-lg-start text-end px-0 mb-5 order-lg-1 order-3'>
                  <div className="development-imgbox">
                    <img src={dev1} alt="" />
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step">
                      {" "}
                      <div className="step-num">2</div>
                    </div>
                  </div>
                  <div className="line d-lg-flex d-none">
                    <div className="step-line"></div>
                  </div>
                </div>
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-3 order-2">
                  <div className="development-textbox">
                    <div className="label">User Flow</div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Map Out User Journeys & Interactions.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Define Optimal Path & Guide Users.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">Create User Flows.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 my-4 pt-4">
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-1 order-2">
                  <div className="development-textbox">
                    <div className="label p-0">Mockups</div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Develop High Fidelity Visual Representations.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Select Color Schemes, Branding Elements, & Visual
                        Hierarchy.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Realistic Preview Of Final Design.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step">
                      {" "}
                      <div className="step-num">3</div>
                    </div>
                  </div>
                  <div className="line d-lg-flex d-none">
                    <div className="step-line"></div>
                  </div>
                </div>
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 order-3">
                  <div className="development-imgbox mb-5">
                    <img src={dev4} alt="" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 mb-5 order-lg-1 order-3">
                  <div className="development-imgbox">
                    <img src={dev3} alt="" />
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step">
                      {" "}
                      <div className="step-num">4</div>
                    </div>
                  </div>
                  <div className="line d-lg-flex d-none">
                    <div className="step-line"></div>
                  </div>
                </div>
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-3 order-2">
                  <div className="development-textbox">
                    <div className="label">Prototyping</div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Build Interactive & Dynamic Prototypes.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Test & Validate The Functionality.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Gather Feedback From Stakeholders & Users.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 my-4  pt-4">
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-1 order-2">
                  <div className="development-textbox">
                    <div className="label p-0">Usability Testing</div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Conduct Testing Sessions With Representative Users.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Identify Usability Issues, Pain Points & Areas of
                        Improvement.
                      </div>
                    </div>
                    <div className="d-flex">
                      <span className="label-point-dot">
                        <GoDot />
                      </span>
                      <div className="label-points p-0">
                        Implement Necessary Adjustments Based On User Feedback.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step">
                      {" "}
                      <div className="step-num">5</div>
                    </div>
                  </div>
                  {/* <div className="line"><div className="step-line"></div></div> */}
                </div>
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 order-3">
                  <div className="development-imgbox">
                    <img src={dev2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DevelopmentProcess;
