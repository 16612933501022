import React, { useEffect } from 'react';
import { MdArrowOutward } from 'react-icons/md';
import video from '../../../Image/ecommerce_video.mp4';
import img1 from '../../../Image/poster/ecommerce-service.jpg'

function Details() {
    useEffect(() => {
        const animatedRow = document.querySelector('.animation-row');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('scrolled');
                }
            });
        }, {
            threshold: 0.5
        });

        if (animatedRow) {
            observer.observe(animatedRow);
        }
        return () => {
            if (animatedRow) {
                observer.unobserve(animatedRow);
            }
        };
    }, []);

    return (
        <>
            <div className="container-fluid px-0 mx-0 mt-3 pt-2">
                <div className="development-services">
                    <div className="details-parabox">
                        <p className='details-para mb-4'>
                        At Nasksoft, we deliver innovative eCommerce and marketplace solutions, including comprehensive website hosting and application development. Our advanced eCommerce framework promotes seamless content management, real-time activity monitoring, and secure payment processing. We encourage businesses to enhance their customer shopping experience, both online and offline, with features like product review management, and easy-to-use shopping carts. The customisable platform authorises scalability, ensuring businesses succeed in competitive markets. Backed by an expert team, we’re earmarked to long-term partnerships and client success.

                        </p>
                        {/* <p className='details-para'>
                           Shopping Cart Development  Solutions make it easy and safe to find, select and buy products and  services. Product information, reviews, related products, pricing, and  promotions further enhance the users’ online shopping experience. We take relevant initiatives in order to make the project moving forward. They never hesitate to ask the relevant competent person to intervene when the situation needs such intervention. Their sympathy and availability is very much appreciated. I warmly and professionally recommend Nasksoft. Our Company has already considered envisioning a long-term relationship with such a talented team.
                        </p> */}
                    </div>


                    <div className="row mx-0 mt-5 pt-2 animation-row">
                        <div className="col-lg-7 col-12 px-0 pb-md-0 pb-4">
                            <div className="services-imgbox">
                                <video poster={img1} autoPlay={true} muted loop id="bgVideo">
                                    <source src={video} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                </video>
                                <div className="frame"></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 mt-lg-0 mt-sm-5 mt-4">
                            <div className="services-optionbox">
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>E-commerce Platform Development</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Amazon & eBay Solutions</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Shopify Development</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Market Place Development</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Details;
