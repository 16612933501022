import React from 'react';
import videoing from '../../Image/banner.mp4';
import img from '../../Image/hero-image.webp';
import { GoArrowRight } from 'react-icons/go';
import NavBar from './NavBar';

function Header() {

  return (
    <>
      <div className='header'>
    
      <video poster={img} autoPlay={true} muted loop id="bgVideo">
          <source src={videoing} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
        <div className='over_lay'></div>
      <NavBar/>
      <div className='header_main'>

        <div className='digital-heading'>
          <p className='first-para'>We Create a Better World by</p>
          <p className='sol'>Digitally Innovating</p>
          <p className='pera'>
          With decades of expertise, we deliver innovative digital design, <br /> development & marketing solutions for future-focused businesses.
          </p>
          <a style={{textDecoration:"none"}} href='#service'>

          <div style={{cursor:"pointer"}} className="apply-btn mt-2">
              <div className="animation-frame"></div>
              <div className="sign"><GoArrowRight /></div>
              <div style={{color:'white'}} className="text">Learn More</div>
            </div>
          </a>
        </div>
      </div>
      </div>
    </>
  );
}

export default Header;
