import React, { useEffect } from 'react'
import Header from '../Componets/Homepage/Header'
import AboutUs from '../Componets/Homepage/About_us'
import Services from '../Componets/Homepage/Services'
import Industries from '../Componets/Industries'
import Global from '../Componets/Homepage/Global'
import News from '../Componets/Homepage/News'
import Project from '../Componets/Homepage/Project'
import Latest from '../Componets/Homepage/Latest'
import Crafting from '../Componets/Homepage/Crafting'
import { useLocation } from 'react-router-dom'

function HomePage() {
    const { hash } = useLocation();
  
    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [hash]);

  return (
    <>
    <Header/>
    <AboutUs/>
    <Services/>
    <Industries/>
    <Crafting/>
    <Latest/>
    <Global/>
    <News/>
    <Project/>
    
    </>
  )
}

export default HomePage
