import React, { useEffect } from 'react';
import { MdArrowOutward } from 'react-icons/md';
import video from '../../../Image/seo_video.mp4';
import img from '../../../Image/poster/digital-markeing.jpg'

function Details() {
    useEffect(() => {
        const animatedRow = document.querySelector('.animation-row');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('scrolled');
                }
            });
        }, {
            threshold: 0.5
        });

        if (animatedRow) {
            observer.observe(animatedRow);
        }
        return () => {
            if (animatedRow) {
                observer.unobserve(animatedRow);
            }
        };
    }, []);

    return (
        <>
            <div className="container-fluid px-0 mx-0 mt-3 pt-2">
                <div className="development-services">
                    <div className="details-parabox">
                        <p className='details-para mb-4'>
                        Maximize your online presence with our comprehensive SEO and digital marketing solutions. Our expert team utilises progressive SEO strategies to enhance visibility, drive organic traffic, and improve search engine rankings. Integrated with our customised social media strategies, we maximize engagement, reach, and conversions to help your brand succeed in the competitive digital domain. We encourage you to stay ahead of the competition and ensure your business stands out with our customized, results-driven approach to SEO and digital marketing success.
                          </p>
                        {/* <p className='details-para'>
                        A strong SEO strategy delivers a steady stream of new traffic directly to your digital front door (aka your awesome website). And the long-term effects of good quality SEO work can pay dividends for years to come. Our SEO experts create custom SEO campaigns that will deliver the results you need to take your business to the next level. A steady stream of qualified leads is there, we just need to put you in front of them.
                        </p> */}
                       
                    </div>
                    <div className="row mx-0 mt-5 pt-2 animation-row">
                        <div className="col-lg-7 col-12 px-0 pb-md-0 pb-4">
                            <div className="services-imgbox">
                                <video poster={img} autoPlay={true} muted loop id="bgVideo">
                                    <source src={video} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                </video>
                                <div className="frame"></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 mt-lg-0 mt-lg-0 mt-sm-5 mt-4">
                            <div className="services-optionbox">
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Search Engine Optimization</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Email Marketing</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Social Media Marketing</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Content Strategy & Optimization</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Details;
