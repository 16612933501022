import React from 'react';
import { GoDotFill } from 'react-icons/go';

function Benefits() {

    const points = [
        {
            bold_text: "Security Compliance:",
            points_para: "Our QA consulting services ensure software adheres to industry standards, protecting against vulnerabilities and cyber threats for maximum security."
        },
        {
            bold_text: "Risk Mitigation:",
            points_para: "With the early testing systems, we prevent potential breakdowns, delivering a stable and reliable software product at launch."
        },
        {
            bold_text: "Efficient Processes:",
            points_para: "At Nasksoft, we simplified and organised QA processes to boost development efficiency, minimizing bottlenecks."
        }, 
        {
            bold_text: "Automated Testing:",
            points_para: "Our strategic QA consulting approaches support CI/CD pipelines for automation, accelerating testing and improving accuracy."
        },
        {
            bold_text: "Enhanced User Satisfaction:",
            points_para: "Our QA testing services deliver a seamless and error-free user experience, leading to higher user satisfaction and increased customer loyalty."
        }, 
        {
            bold_text: "Cost Savings:",
            points_para: "We prefer to identify bugs at the early stage of development, reducing time and costs, and avoiding expensive fixes at later development stages."
        },
    ]; 

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="services-benefits">
                    <div className="heading mx-1 mt-lg-0 mt-5 pt-lg-0 pt-sm-5">Benefits</div>
                    {
                        points.map((point, index) => (
                            <div className="benefits-points d-flex">
                                <span className='bullet'><GoDotFill /></span>

                                <div className="points" key={index}>
                                    <span className='bold-text pe-1'>{point.bold_text}</span>
                                    {point.points_para}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default Benefits;
