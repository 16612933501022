import React from 'react'
import Banner from '../Componets/Services/Cloud-Services/Banner'
import Details from '../Componets/Services/Cloud-Services/Details'
import Benefits from '../Componets/Services/Cloud-Services/Benefits'
import NewNavBar from '../Componets/Homepage/NewNavBar'

function CloudServices() {
  return (
    <>
    <NewNavBar/>
     <Banner/>
     <Details/>
     <Benefits/>
    </>
  )
}

export default CloudServices
