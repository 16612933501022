import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import k_logo from '../../Image/k_logo.png';

function OurDetails() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            // once: true,
        });
    }, []);

    return (
        <>
            <div className="container-fluid px-0 pb-5 mx-0 my-5">
                <div className="aboutus-details">
                    <div className="row m-0 px-sm-5 px-4">
                        <div className="background_image d-flex" style={{ flexWrap: 'wrap' }}>
                            <div className="second_img">
                                <img data-aos="fade-right" data-aos-delay="1000" src={k_logo} alt="" />
                            </div>
                            <div className="col-lg-4 col-12 p-0 d-flex align-items-center justify-content-center">
                                {/* <div className="head-details ps-lg-4">
                                    <div data-aos="fade-right" data-aos-delay="1000" className="k_log0">
                                        <img src={k_logo} alt="" />
                                    </div>
                                    <div style={{position:"absolute",top:"0",paddingTop:"24px",height:"100%", paddingLeft:'23px'}}>


                                    <div className="heading text-lg-start text-center">
                                    Proficient <br />
                                    Technologist <br />
                                    at the Core                                        
                                    </div>
                                    <div className="sub-heading text-lg-start text-center">
                                    We are emerging as the ultimate digital partner in innovative solutions that drive growth.
                                    </div>
                                    </div>
                                </div> */}
                                <div className="head_details">
                                    <div className="heading"> Proficient <br />
                                    Technologist <br />
                                    at the Core </div>
                                    <div className="sub-heading">
                                    We are emerging as the ultimate digital partner in innovative solutions that drive growth.
                                    </div>
                                </div>
                                <div className="new_head-details ps-lg-4">
                                    <div className="heading text-lg-start text-center">
                                    Proficient Technologist at the Core
                                    </div>
                                    <div className="sub-heading text-lg-start text-center">
                                    We are emerging as the ultimate digital partner in innovative solutions that drive growth.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-12 px-lg-5 pt-3">
                                <p className='details-para px-lg-0 px-sm-3 px-1'>
                                As the leading software development company, Nasksoft excels in delivering custom-built software solutions across diverse industries including healthcare, finance, technology and government organizations. We specialise in developing customised solutions to meet the client's distinctive requirements.
                                </p>
                                <p className='details-para my-4 px-lg-0 px-sm-4 px-2'>
                                Nasksoft has built a strong reputation partnering with considerable clients. Our team thoroughly assess to understands your requirements, ensuring the best possible industry-focused IT solutions. With an excellent history, positive referrals and an experienced workforce, Nasksoft guarantee your success.
                                </p>
                                <p className='details-para  px-lg-0 px-sm-4 px-2'>
                                We utilise our deep expertise and customer-centric approach to form a reliable, low-risk partnership with your business. By collaborating closely with your team, we pinpoint cost-effective strategies to grow your enterprise, yielding measurable results through focused, outcome-oriented digital solutions.
                                </p>
                                <p className='details-para  px-lg-0 px-sm-4 px-2'>
                                Is your business ready to achieve greater results? Nasksoft is here to lend a keen ear to your challenges and develop innovative solutions designed to bring measurable success and real value to your enterprise.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OurDetails;
