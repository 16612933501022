import React from 'react'
import NewNavBar from '../Componets/Homepage/NewNavBar'
import LatestNews from '../Componets/News/LatestNews'
import Banner from '../Componets/News/Banner'
// import NewsSingel from './NewsSingel'
function News() {
  return (
    <div>
      <NewNavBar/>
      <Banner/>
      <LatestNews/>
    </div>
  )
}

export default News
