import React, { useState, useEffect, useRef } from 'react';
import img from '../../Image/group.png';
import img1 from '../../Image/Vector (10).png';
import img2 from '../../Image/Group 143725906.png';
import img3 from '../../Image/Vector (11).png';
import img4 from '../../Image/froup.svg';
import 'aos/dist/aos.css'; // Import AOS styles

const cardData = [
  { img: img, number: 100, text: 'Customers' },
  { img: img2, number: 150, text: 'Product Deployments' },
  { img: img1, number: 25, text: 'Countries' },
  { img: img3, number: 250, text: 'Interactions Everyday' }, // Display this as "700M"
  { img: img4, number: 300, text: 'Professional' }
];

function Global() {
  const [counters, setCounters] = useState(cardData.map(() => 0));
  const [hasStarted, setHasStarted] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasStarted) {
          setHasStarted(true);
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [hasStarted]);

  useEffect(() => {
    if (hasStarted) {
      const intervals = cardData.map((card, index) => {
        const increment = card.number / 100; // Adjust the speed of the count-up
        return setInterval(() => {
          setCounters((prevCounters) => {
            const newCounters = [...prevCounters];
            if (newCounters[index] < card.number) {
              newCounters[index] = Math.min(newCounters[index] + increment, card.number);
            }
            return newCounters;
          });
        }, 30); // Adjust interval time for smoothness
      });

      return () => intervals.forEach((interval) => clearInterval(interval));
    }
  }, [hasStarted]);


  return (
    <div className="Global" ref={ref}>
      <div className="container-fluid">
        <h3>Our Global Impact</h3>
        <p>
        For over two decades, we’ve empowered organisations worldwide to potentially adopt digital transformation. Over this period, Nasksoft has grown into a leading force in innovative technology solutions.
        </p>
        <div className="m-0 d-flex justify-content-between">
          {cardData.map((card, index) => (

              <div className="global-card d-flex align-items-center flex-column">
                <div className="global-img">
                  <img  data-aos="fade-down"  src={card.img} alt=".." style={{ verticalAlign: 'baseline' }} />
                </div>
            <div className='text-center' data-aos="fade-up">
            <h4>
                  {Math.floor(counters[index])}
                  {index === 3 ? 'M' : (counters[index] > 0 ? '+' : '')}
                </h4> {/* Add "M" for the specific index, "+" for others */}
                <h6>{card.text}</h6>
            </div>
              </div>
    
          ))}
        </div>
      </div>
    </div>
  );
}

export default Global;
