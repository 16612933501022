import React from 'react'

import Banner from '../Componets/Services/Ecommerce-services/Banner'
import Details from '../Componets/Services/Ecommerce-services/Details'
import Benefits from '../Componets/Services/Ecommerce-services/Benefits'
import DeveloperProcess from '../Componets/Services/Ecommerce-services/DeveloperProcess'
import OurTools from '../Componets/Services/Ecommerce-services/OurTools'
import NewNavBar from '../Componets/Homepage/NewNavBar'

function EcommerceServices() {
  return (
    <>
    <NewNavBar/>
     <Banner/>
     <Details/>
     <Benefits/>
     <DeveloperProcess/>
     <OurTools/> 
    </>
  )
}

export default EcommerceServices
