import React from 'react'

import Banner from '../Componets/Services/QA-Services/Banner'
import OurTools from '../Componets/Services/QA-Services/OurTools'
import Benefits from '../Componets/Services/QA-Services/Benefits'
import Details from '../Componets/Services/QA-Services/Details'
import DeveloperProcess from '../Componets/Services/QA-Services/DeveloperProcess'
import NewNavBar from '../Componets/Homepage/NewNavBar'

function QAServices() {
  return (
    <>
    <NewNavBar/>
     <Banner/>
     <Details/>
     <Benefits/>
     <DeveloperProcess/>
     <OurTools/> 
    </>
  )
}

export default QAServices
