import React from 'react'
import Banner from '../Componets/Services/Seo-Services/Banner'
import Details from '../Componets/Services/Seo-Services/Details'
import Benefits from '../Componets/Services/Seo-Services/Benefits'
import DeveloperProcess from '../Componets/Services/Seo-Services/DeveloperProcess'
import OurTools from '../Componets/Services/Seo-Services/OurTools'
import NewNavBar from '../Componets/Homepage/NewNavBar'

function SeoServices() {
  return (
    <>
    <NewNavBar/>
     <Banner/>
     <Details/>
     <Benefits/>
     <DeveloperProcess/>
     <OurTools/> 
    </>
  )
}

export default SeoServices
