import React from 'react'
import { GoDot } from 'react-icons/go'
import dev from '../../../Image/Seo-services/Frame 1300193286.svg'
import dev1 from '../../../Image/Seo-services/SEO-pana 2.svg'
import dev2 from '../../../Image/Seo-services/SEO analytics team-cuate 1.svg'

function DevelopmentProcess() {
    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row m-0">
                    <div className="development-process">
                        <div className="col-10 p-0">
                            <div className="heading  new-seo-heading">Our SEO & Digital Marketing Process</div>
                            <div className="row m-0">
                                <div className="col-lg-5 col-sm-9 pb-lg-0 pb-4 col-12 p-0 mb-lg-0 mb-5 order-lg-1 order-2">
                                    <div className="development-textbox">
                                        <div className="label">SEO Audit & Research</div>
                                        <div className="pad-box">
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">Competitor Analysis</div>
                                            </div>
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">Keyword Research & Identification</div>
                                            </div>
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">Website Health Check</div>
                                            </div>
                                            <div className='d-flex'>
                                                <span className='label-point-dot'><GoDot /></span>
                                                <div className="label-points px-0">SEO Strategy Development</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-12 p-0 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 text-center order-lg-2 order-1">
                                    <div className="step-box">
                                        <div className="step"> <div className="step-num">1</div></div>
                                    </div>
                                    <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                                </div>
                                <div className="col-lg-5 col-12 text-lg-start text-end px-0 pb-lg-5 pb-4 mb-2 order-3" style={{marginBottom:"120px"}}>
                                    <div className="development-imgbox">
                                        <img src={dev} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-lg-5 col-12 text-lg-start text-end px-0 mb-5 order-lg-1 order-3">
                                    <div className="development-imgbox">
                                        <img src={dev1} alt="" />
                                    </div>
                                </div>
                                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                                    <div className="step-box">
                                        <div className="step"> <div className="step-num">2</div></div>
                                    </div>
                                    <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                                </div>
                                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-3 order-2">
                                    <div className="development-textbox">
                                        <div className="label">Technical SEO Audit & On-Page Optimization</div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Website Crawling & Indexing Issues</div>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Site Architecture & URL Structure</div>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Core Web Vitals & Mobile Optimization</div>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Meta Tags, Headers, and Content Optimization</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 my-4 pt-4">
                                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-1 order-2">
                                    <div className="development-textbox">
                                        <div className="label">Off-Page SEO & Reporting</div>
                                      <div className="pad-box">
                                      <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Backlink Strategy & Outreach</div>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Local SEO & Citation Building</div>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Monthly Performance Reporting</div>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='label-point-dot'><GoDot /></span>
                                            <div className="label-points px-0">Continuous Improvements & Adjustments</div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                                    <div className="step-box">
                                        <div className="step"> <div className="step-num">3</div></div>
                                    </div>
                                    {/* <div className="line"><div className="step-line"></div></div> */}
                                </div>
                                <div className="col-lg-5 col-12 text-lg-start text-end px-0 order-3">
                                    <div className="development-imgbox">
                                        <img src={dev2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevelopmentProcess
