import React from 'react'
import { GoDot } from 'react-icons/go'
import dev from '../../../Image/E-commerce/Frame 1300193274.svg'
import dev1 from '../../../Image/E-commerce/Ecommerce checkout laptop-cuate 1.svg'
import dev2 from '../../../Image/E-commerce/Frame 1300193334.svg'
import dev3 from '../../../Image/E-commerce/Frame 1300193335.svg'
import dev4 from '../../../Image/E-commerce/Group 427319004.svg'

function DevelopmentProcess() {
  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row m-0">
          <div className="development-process">
            <div className="col-10 p-0">
              <div className="heading">Our E-Commerce Development Process</div>
              <div className="row m-0">
                <div className="col-lg-5 col-sm-9 pb-lg-0 pb-4 col-12 p-0 mb-lg-0 mb-5 order-lg-1 order-2">
                  <div className="development-textbox">
                    <div className="label p-0">Planning and Research</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Define business goals and target audience.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Analyze competitors and market trends.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Determine product offerings and pricing strategy.</div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 col-12 p-0 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">1</div></div>
                  </div>
                  <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                </div>
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 pb-lg-5 pb-4 mb-2 order-3" style={{marginBottom:"160px"}}>
                  <div className="development-imgbox">
                    <img src={dev} alt="" />
                  </div>
                </div>
              </div>
              <div className="row mx-0 mt-5 ">
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 mb-5 order-lg-1 order-3"  style={{marginBottom:"140px"}}>
                  <div className="development-imgbox">
                    <img src={dev1} alt="" />
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">2</div></div>
                  </div>
                  <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                </div>
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-3 order-2">
                  <div className="development-textbox">
                    <div className="label">Choosing the Right Platform</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Evaluate eCommerce platforms (e.g., Shopify, Woo Commerce, Magento).</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Consider scalability, budget, and feature requirements.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Select a hosting solution.</div>
                    </div>
                  </div>    
                </div>
              </div>
              <div className="row m-0 my-4 pt-4">
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-1 order-2">
                  <div className="development-textbox">
                    <div className="label p-0">Design and User Experience</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Create wireframes and design prototypes.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Focus on responsive design for mobile and desktop.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Ensure intuitive navigation and user-friendly interfaces.</div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">3</div></div>
                  </div>
                  <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                </div>
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 order-3" style={{marginBottom:"100px"}}>
                  <div className="development-imgbox">
                    <img src={dev2} alt="" />
                  </div>
                </div>
              </div>
              <div className="row mt-0 mb-5">
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 mb-5 order-lg-1 order-3"  style={{marginBottom:"160px"}}>
                  <div className="development-imgbox">
                    <img src={dev3} alt="" />
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">4</div></div>
                  </div>
                  <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                </div>
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-3 order-2">
                  <div className="development-textbox">
                    <div className="label">Development</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Set up the eCommerce platform and customize as needed.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Integrate payment gateways and shipping options.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Implement necessary security measures (SSL, data protection).</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 mb-4">
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-1 order-2">
                  <div className="development-textbox">
                    <div className="label p-0">Testing, Launch and Marketing</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Conduct functional, usability, and performance testing.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Utilize SEO, social media, and email marketing to drive traffic.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Monitor performance and analytics for ongoing optimization.</div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">5</div></div>
                  </div>
                  {/* <div className="line"><div className="step-line"></div></div> */}
                </div>
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 order-3">
                  <div className="development-imgbox">
                    <img src={dev4} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DevelopmentProcess
