import React from 'react';
import selenium from '../../../Image/QA-services/Frame 1300193300.png';
import postman from '../../../Image/QA-services/Frame 1300193301.png';
import jira from '../../../Image/QA-services/Frame 1300193302.png';
import docker from '../../../Image/QA-services/Frame 1300193303.png';
import github from '../../../Image/QA-services/Frame 1300193304.png';


const tools = [
    { id: 1, name: 'Selenium', image: selenium },
    { id: 2, name: 'Postman', image: postman },
    { id: 3, name: 'Jira', image: jira },
    { id: 4, name: 'Docker', image: docker },
    { id: 5, name: 'GitHub', image: github }, 
];


function OurTools() {
    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="our-tools">
                    <div className="row m-0 p-0">
                        <div className="col-xl-4 col-lg-5 col-12 p-0">
                            <div className="tool-text new-tool-text">
                                <div className="heading text-lg-start text-center">
                                Technologies & Tools Stack For Powering Development
                                </div>
                                <div className="tool-para text-lg-start text-center">
                                Collaborate with Nasksoft’s IT experts to create exceptional custom software 
                                using advanced frameworks and technologies.
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-12 ps-md-5 pe-lg-0 px-4 pe-md-5">
                            <div className="row px-3 mx-0 mt-4 pt-4 d-flex justify-content-lg-between justify-content-center">
                                {tools.map((tool) => (
                                    <div key={tool.id} className="col-lg-2 col-3 col-sm-4 col-6 px-lg-0 p-sm-4 p-3 pt-lg-3">
                                        <div className="tools-iconbox">
                                            <div className="icon">
                                                <span className="icon-img-1">
                                                    <img src={tool.image} alt={tool.name} />
                                                </span>
                                                <div className="icon-name-1">{tool.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OurTools;
