import React from 'react';

import img from '../../Image/Icon.png';
import img1 from '../../Image/Icon (1).png';
import img2 from '../../Image/Icon (2).png';
import img3 from '../../Image/Icon (3).png';

const stories = [
  {
    heading: "Innovation",
    description:
      "We embrace continuous improvement by exploring the latest technologies and ensuring innovative solutions to meet evolving client needs.",
    img: img,
    animation : 'fade-right',
  },
  {
    heading: "Quality",
    description:
      "Our commitment to quality ensures every project is executed with precision, yielding excellent performance, and user satisfaction.",
    img: img1,
    animation: "fade-left",
  },
  {
    heading: "Integrity",
    description:
      "We uphold the highest ethical standards, operating business with transparency, honesty, and a deep commitment to professional ethics.",
    img: img3,
    animation : 'fade-right',
  },
  {
    heading: "Collaboration",
    img: img2,
    description:
      "We operate closely with our clients, aligning with their goals and encouraging success through strong partnerships and teamwork.",
      animation: "fade-left",
  },
];

function Story() {

  return (
    <>
      <div className="container-fluid mx-0 pt-4 pb-4 my-5">
        <div className="story-values">
          <div className="row m-0 d-flex justify-content-center" style={{overflow:'hidden'}}>
            <div className="col-lg-8 col-12 p-lg-0 px-1">
              <div className="story-heading text-center mb-4 pb-2">
              Core Values of Our Commitment to Excellence
              </div>
            </div>
            {stories.map((story, index) => (
              <div className="col-lg-6 col-md-10 col-12 px-3" key={index}  data-aos={story.animation}>
                <div className="story-card pb-3">
                  <div className="row m-0">
                    <div className="col-sm-4 col-12 p-0">
                      <div className="img-box d-sm-flex d-none justify-content-center">
                        <div className="imgbox">
                          <img className='w-100 h-100' src={story.img} alt={story.heading} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-8 col-12 p-0">
                      <div className="card-texts">
                        <div className="heading text-sm-start text-center">{story.heading}</div>
                        <div className="card-body new_card_body text-sm-start text-center">{story.description}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Story;
