import React from 'react';
import img from '../../Image/unsplash_vbAEHCrvXZ0.png';
import img1 from '../../Image/unsplash_XJXWbfSo2f0.png';
import img2 from '../../Image/unsplash_2EJCSULRwC8.png';
import img3 from '../../Image/unsplash_Im7lZjxeLhg.png';
import img4 from '../../Image/unsplash_vEE00Hx5d0Q.png';
import img5 from '../../Image/unsplash_6lcT2kRPvnI.png';
import img6 from '../../Image/unsplash_ourQHRTE2IM.png';
import img7 from '../../Image/unsplash_XtUd5SiX464.png';
import img8 from '../../Image/unsplash_PO8Woh4YBD8.png';
import img9 from '../../Image/unsplash_SJSpo9hQf7s.png';
import img10 from '../../Image/unsplash_w6OniVDCfn0.png';
import img11 from '../../Image/unsplash_8hgmG03spF4 (1).png';

function Blogdata() {
  return (
    <>
      <div className="container-fluid">
        <div className="blog-data px-3">
          <div className="row my-5">
            <div className="col-lg-9 col-12">
              
             
              <div className="bolgdata-card" style={{ paddingRight: '30px' }}>
                <div className="bl-1">
                  <div className="row m-0">
                <div className="col-6 p-0"><img src={img} alt=".../" className="img-fluid" /></div>
                <div className="col-6 p-0">
                <div className="bl-heading mt-2">
                    <h3 className="blog-title">Porttitor pharetra, consectetur viverra est nisl a, vulputate id...</h3>
                    <p className="blog-desc">
                      Dui massa sapien ornare leo. Sagittis, sollicitudin sed integer maecenas sit. Nibh suspendisse lectus hendrerit pretium...
                    </p>
                    <div className="blo-tec d-flex justify-content-between">
                      <h5>13 Sept, 2021</h5>
                      <h5>Technology</h5>
                    </div>
                  </div>
                </div>
                 
                </div>
              </div>
              </div>

              {/* Cards Row */}
              <div className="row d-flex justify-content-between">
                <div className="col-md-6 col-12 mb-3">
                  <div className="blg-2">
                    <img src={img1} alt="../" className="img-fluid" />
                    <h4 className="mt-2">Diam amet non placerat risus magna non volutpat.</h4>
                    <div className="blo-tec d-flex justify-content-between px-3">
                      <h5>13 Sept, 2021</h5>
                      <h5>Technology</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <div className="blg-2">
                    <img src={img2} alt="../" className="img-fluid" />
                    <h4 className="mt-2">Porttitor pharetra, consectetur viverra est...</h4>
                    <div className="blo-tec d-flex justify-content-between px-3">
                      <h5>13 Sept, 2021</h5>
                      <h5>Technology</h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Row */}
              <div className="row d-flex justify-content-between">
                <div className="col-md-6 col-12 mb-3">
                  <div className="blg-2">
                    <img src={img3} alt="../" className="img-fluid" />
                    <h4 className="mt-2">Egestas leo a dolor venenatis vel porttitor vitae...</h4>
                    <div className="blo-tec d-flex justify-content-between px-3">
                      <h5>13 Sept, 2021</h5>
                      <h5>Technology</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <div className="blg-2">
                    <img src={img4} alt="../" className="img-fluid" />
                    <h4 className="mt-2">Orci suspendisse leo, congue tortor, ultricies egestas...</h4>
                    <div className="blo-tec d-flex justify-content-between px-3">
                      <h5>13 Sept, 2021</h5>
                      <h5>Technology</h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* Third Row */}
              <div className="row d-flex justify-content-between">
                <div className="col-md-6 col-12 mb-3">
                  <div className="blg-2">
                    <img src={img5} alt="../" className="img-fluid" />
                    <h4 className="mt-2">Adipiscing lacus dui rutrum quam. In morbi facilisis elit.</h4>
                    <div className="blo-tec d-flex justify-content-between px-3">
                      <h5>13 Sept, 2021</h5>
                      <h5>Technology</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <div className="blg-2">
                    <img src={img6} alt="../" className="img-fluid" />
                    <h4 className="mt-2">Volutpat volutpat turpis risus nunc in nisl habitasse leo.</h4>
                    <div className="blo-tec d-flex justify-content-between px-3">
                      <h5>13 Sept, 2021</h5>
                      <h5>Technology</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Sidebar */}
            <div className="col-lg-3 col-12 ps-2 pe-2">
              <div className="my-2">
                <h6>Featured Blogs</h6>

                {[img7, img8, img9, img10].map((image, index) => (
                  <div className="blog-left-card mb-3" key={index}>
                    <div className="left-down-card d-flex">
                      <img style={{ marginRight: '10px' }} src={image} alt="..." className="img-fluid" />
                      <div>
                        <h4>Faucibus interdum cras imperdiet fames. Ullamcorper.</h4>
                        <div className="blo-tec d-flex justify-content-between">
                          <h5>13 Sept, 2021</h5>
                          <h5>Technology</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="blog-l-heading">
                  <h3>Featured Video</h3>
                  <img src={img11} alt="../" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blogdata;
