import React from 'react';
import { GoDotFill } from 'react-icons/go';

function Benefits() {

    const points = [
        {
            bold_text: "Improved Visibility:",
            points_para: "Our effective SEO strategies guarantee your website ranks higher on search engines, improving visibility and discoverability."
        },
        {
            bold_text: "Maintained Online Presence:",
            points_para: "We build a strong online presence through our digital marketing strategies for your brand, encouraging user trust and credibility."
        },
        {
            bold_text: "Organic Traffic Growth:",
            points_para: "By optimizing your site and implementing SEO, we draw more organic visitors, leading to increased engagement and potential customers."
        }, 
        {
            bold_text: "Increase Conversions:",
            points_para: "With targeted digital marketing strategies, we convert traffic into sales, delivering higher ROI for your business than ever."
        },
        {
            bold_text: "Competitive Advantage:",
            points_para: "We encourage businesses to stay ahead with personalised SEO tactics, positioning their brand for sustained success and growth."
        }, 
        {
            bold_text: "Better User Engagement:",
            points_para: "At Nasksoft, we encourage significant user interactions, driving engagement and loyalty to your brand with our digital marketing services."
        },
        {
            bold_text: "Promote Brand Authority:",
            points_para: "Our social media tactics establish your brand as an industry leader among high competition, increasing credibility and trustworthiness."
        },
        {
            bold_text: "Data-Driven Insights:",
            points_para: "We offer actionable insights through analytics, permitting continuous optimisation for better performance and results."
        },
    ]; 

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="services-benefits">
                    <div className="heading mx-1">Benefits</div>
                    {
                        points.map((point, index) => (
                            <div className="benefits-points d-flex">
                                <span className='bullet'><GoDotFill /></span>

                                <div className="points" key={index}>
                                    <span className='bold-text pe-1'>{point.bold_text}</span>
                                    {point.points_para}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default Benefits;
