import React from 'react';
import { GoDotFill } from 'react-icons/go';

function Benefits() {

    const points = [
        {
            bold_text: "Extended Market Reach:",
            points_para: "Through our eCommerce strategies, we expand your business globally, breaking geographical barriers and allowing access to new customer markets."
        }, 
        {
            bold_text: "Personalised Shopping Experience:",
            points_para: "Customers receive personalised experiences based on their browsing habits and preferences, boosting satisfaction and increasing loyalty."
        }, 
        {
            bold_text: "Product Catalogue:",
            points_para: "We develop custom catalogue features, providing detailed product information, categories, and keyword searches for easy product discovery."
        },  
        {
            bold_text: "Shopping Cart:",
            points_para: "Customers can easily add, compare, and remove products, ensuring a smooth checkout process with minimal effort."
        }, 
        {
            bold_text: "Secure Payments Gateways:",
            points_para: "Integrated payment gateways provide secured transactions, providing customers with a reliable and seamless billing experience."
        },  
        {
            bold_text: "Mobile-Friendly:",
            points_para: "Our responsive design ensures your eCommerce site works smoothly across all mobile devices, enhancing customer convenience everywhere."
        }
    ]; 

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="services-benefits">
                    <div className="heading mx-1">Benefits</div>
                    {
                        points.map((point, index) => (
                            <div className="benefits-points d-flex">
                                <span className='bullet'><GoDotFill /></span>

                                <div className="points" key={index}>
                                    <span className='bold-text pe-1'>{point.bold_text}</span>
                                    {point.points_para}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default Benefits;
