import React from 'react'

function CareerBanner() {
    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row m-0">
                    <div className="mainframe-video">
                        <div className="video-frame"></div>
                        <div className="col-lg-8 col-12 px-4">
                            <div className="head-tag pb-sm-3">Careers</div>
                            <p className="head-para mb-0 px-lg-5 px-0 py-0">
                            Start your adventure in the wonderful world of Nasksoft. We’re Always Looking for Hardworking, Collaborative, and Curious Team Members. Join us to unlock your possibilities and go the extra mile.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerBanner
