import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from '../../Image/homepage_carousel_images/Component 1-2.svg';
import img2 from '../../Image/homepage_carousel_images/Component 3-4.svg';
import img3 from '../../Image/homepage_carousel_images/Component 5-6.svg';
import img4 from '../../Image/homepage_carousel_images/Component 7-8.svg';
function Deslider() {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      return (
        <Slider {...settings}>
      <div>
      <img src={img1} className="d-block w-100" alt="Slide 1" />
      </div>
      <div>
      <img src={img2} className="d-block w-100" alt="Slide 1" />
      </div>
      <div>
      <img src={img3} className="d-block w-100" alt="Slide 1" />
      </div>
      <div>
      <img src={img4} className="d-block w-100" alt="Slide 1" />
      </div>
    </Slider>
    );
}

export default Deslider