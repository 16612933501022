import React from 'react'
import Blog from '../Componets/Blogs/Blog'
import NewNavBar from '../Componets/Homepage/NewNavBar'
import Blogdata from '../Componets/Blogs/Blogdata'

function Blogs() {
  return (
    <>
    <NewNavBar/>
    <Blog/>
    <Blogdata/>
    </>
  )
}

export default Blogs
