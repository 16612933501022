import React from 'react'
import NewNavBar from '../Componets/Homepage/NewNavBar'
import Data from '../Componets/Contact_us.js/Data'
import Banner from '../Componets/Contact_us.js/Banner'

function Contact_us() {
  return (
    <div>
      <NewNavBar/>
      <Banner/>
      <Data/>
    </div>
  )
}

export default Contact_us
