import React, { useState } from "react";
import img from "../../Image/nasksoft-logo.png";
// import { BsTelephoneFill } from "react-icons/bs";
import img1 from "../../Image/email.svg";
import img2 from "../../Image/contact.svg";
import { CgFacebook } from "react-icons/cg";
import {
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPinterest,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { MdOutlineCopyright } from "react-icons/md";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
// import line from '../../Image/line.svg'
function Footer() {
  const [email, setEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState("");
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleSubmit = () => {
    if (isValidEmail(email)) {
      setError("");
      handleClick();
    } else {
      setError("Please enter a valid Email");
    }
  };
  return (
    <>
      <div className="contianer-fluid">
        <div className="footer">
          <div className="footer-logo">
            <img src={img} alt="..." />
            <p>
            Nasksoft enhances business efficiency through innovative digital technology. We provide comprehensive solutions for e-commerce businesses, from branding to sales growth. Our revolutionary solutions are designed to empower the business community, with a strong commitment to delivering excellence and ensuring customer satisfaction.
            </p>
            <div className="row my-5">
              <div className="col-lg-3 col-md-6 col-sm-6 p-0">
                <div className="footer-ul">
                  <ul className="px-2 ">
                    <label className="mb-2">Services</label>

                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/development-services"}
                    >
                      <li>Web Development</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/development-services"}
                    >
                      <li>Shopify Development</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/design-services"}
                    >
                      <li>Product Design</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/design-services"}
                    >
                      <li>UI/UX Design</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/ecommerce-services"}
                    >
                      <li>Amazon & eBay Solutions</li>
                    </Link>

                    {/* <Link style={{textDecoration:"none",cursor:"pointer"}} to={'/seo-services'}></Link> */}
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/quailty-services"}
                    >
                      <li>QA Testing & Automation</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/quailty-services"}
                    >
                      <li>IT Consulting Services</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/quailty-services"}
                    >
                      <li>Maintenance & Support Services</li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 ">
                <div className="footer-ul">
                  <ul className="px-2">
                    <label></label>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/development-services"}
                    >
                      <li>Mobile App Development</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/development-services"}
                    >
                      <li>Custom Software Development</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/ecommerce-services"}
                    >
                      <li>E-commerce Solutions</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/cloud-services"}
                    >
                      <li>DevOps Services</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/quailty-services"}
                    >
                      <li>Digital Transformation</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/cloud-services"}
                    >
                      <li>Cloud Migration & Management</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/design-services"}
                    >
                      <li>Mobile App Design</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/design-services"}
                    >
                      <li>Brand Design</li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6  ul-li">
                <div className="footer-ul">
                  <ul className="px-2">
                    <label className="mb-2">Quick Links</label>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/"}
                    >
                      <li>Home</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/about-us"}
                    >
                      <li>About Us</li>
                    </Link>
                    {/* <Link style={{textDecoration:"none",cursor:"pointer"}} to={'/'}>
                                        <li>Blogs</li>
                                        
                                        </Link> */}
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/career"}
                    >
                      <li>Careers</li>
                    </Link>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={"/contact-us"}
                    >
                      <li>Contact us</li>
                    </Link>

                    {/* <li>Services</li>
                                        <li>Careers</li>
                                        <li>Insights</li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6  ul-li">
                <div className="footer-ul">
                  <ul className="px-2 ">
                    <label className="mb-2">Keep in Touch</label>
                      <div className="mt-sm-0 mt-4">
                        <span className="phone-icon"
                          style={{ marginLeft: "2px", marginRight: "18px" }}
                        >
                          <img className="contact_icon" src={img2} alt="..."></img>
                        </span>
                        +92 305 1115551
                      </div>
                      <div className="my-3">
                        <span className=" me-sm-3 me-2">
                          <img className="mail_icon" src={img1} alt=".../" />
                        </span>
                        contact@nasksoft.com
                      </div>
                      <div className="d-flex">
                      <div style={{marginTop:'3px'}}>
                      <FaLocationDot className="pin_icon" style={{fontSize:'20px' , marginRight:"20px" , marginLeft:'3px'}} />
                      </div>
                      Building No. 21, 3rd Floor, Block-CCA, DHA Phase 8 - Ex Park View Phase-8 Lahore, 54000
                      </div>
                  </ul>
                  <div className="follow">
                    <h3>Follow Us</h3>
                    <div>
                      <div className="footer-icon">
                        <a
                          target="_blank" rel="noreferrer"
                          href="https://www.facebook.com/Nasksoft/"
                        >
                          <div>
                            <CgFacebook />
                          </div>
                        </a>
                        <a
                          target="_blank" rel="noreferrer"
                          href="https://www.linkedin.com/company/Nasksoft/"
                        >
                          <div>
                            <FaLinkedinIn />
                          </div>
                        </a>
                        <a
                          target="_blank" rel="noreferrer"
                          href="https://www.instagram.com/Nasksoft/"
                        >
                          <div>
                            <FaInstagram />
                          </div>
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/Nasksoft">
                          <div>
                            <FaTwitter />
                          </div>
                        </a>
                      </div>
                      <div className="footer-icon">
                        <a
                          target="_blank" rel="noreferrer"
                          href="https://www.pinterest.co.uk/Nasksoftofficial/"
                        >
                          <div>
                            <FaPinterest />
                          </div>
                        </a>
                        <a
                          target="_blank" rel="noreferrer"
                          href="https://www.youtube.com/@Nasksoft"
                        >
                          <div>
                            <FaYoutube />
                          </div>
                        </a>
                        <a
                          target="_blank" rel="noreferrer"
                          href="https://www.tiktok.com/@Nasksoft"
                        >
                          <div>
                            <FaTiktok />
                          </div>
                        </a>
                        <a
                          target="_blank" rel="noreferrer"
                          href="https://maps.app.goo.gl/nwytkdEYewKQCTYNA"
                        >
                          <div>
                            <FaMapMarkerAlt />
                          </div>
                        </a>
                      </div>
                    </div>
                    <h4>Subscribe for Exclusive Updates</h4>
                    <div style={{ position: "relative" }} className="email">
                      <input
                        type=""
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Your Email"
                      />
                      <button onClick={handleSubmit}>Subscribe</button>
                      <span
                        className="text-danger"
                        style={{
                          position: "absolute",
                          whiteSpace: "nowrap",
                          top: "100%",
                        }}
                      >
                        {error}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="m-0" />
            <div className="footer-terms d-flex align-items-center justify-content-center">
              {/* <span className='d-flex'>
                                    <div className="t-c">Terms & Conditions</div>
                                    <img src={line} alt="..." />
                                    <div className='privacy'>Privacy</div>
                                </span> */}
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  Email Sent Succcessfully
                </Alert>
              </Snackbar>
              <div className="terms-left">
                <span className="d-flex align-items-center justify-content-center">
                  <MdOutlineCopyright style={{ fontSize: "14px" }} />
                  <h4
                    style={{
                      fontSize: "14px",
                      marginTop: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    2024, Copyrights Nasksoft, All rights are reserved.
                  </h4>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
