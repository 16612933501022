import React from 'react'
import OurDetails from '../Componets/AboutUs/OurDetails'
import Story from '../Componets/AboutUs/Story'
import Global from '../Componets/Homepage/Global'
import Banner from '../Componets/AboutUs/Banner'
import NewNavBar from '../Componets/Homepage/NewNavBar'
import OurMisson from '../Componets/AboutUs/OurMisson'


function AboutUs() {
  return (
    <>
     <NewNavBar/>
     <Banner/> 
     <OurDetails/>
     <OurMisson/>
     <Story/>
     <Global/>
    </>
  )
}

export default AboutUs
