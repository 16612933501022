import React from 'react';
import { GoDotFill } from 'react-icons/go';

function Benefits() {

    const points = [
        {
            bold_text: "Customization:",
            points_para: "Our custom-made solutions are designed to meet unique business requirements, ensuring enhanced functionality and optimal performance."
        },
        {
            bold_text: "Safety & Reliability:",
            points_para: "Promises secure systems adhering to industry standards, protecting your business from risks and ensuring data protection."
        },
        {
            bold_text: "Technical Support:",
            points_para: "Provides constant 24/7 support and troubleshooting, ensuring smooth operation and prompt resolution of any possible issues."
        },
        {
            bold_text: "Business Optimization:",
            points_para: "Streamlines processes, improving workflow efficiency and aligning software with your specific business objectives."
        },
        {
            bold_text: "Cost-Effective:",
            points_para: "Facilitates long-term costs by offering sustainable, efficient solutions, and minimizing excessive expenses on updates and maintenance."
        },
        {
            bold_text: "Integration:",
            points_para: "We incorporate new technologies and tools, keeping your business competitive in an ever-changing market."
        },
        {
            bold_text: "Competitive Edge:",
            points_para: "Boosts operational efficiency, enabling you to stay ahead in the market through innovative and optimized software."
        },
        {
            bold_text: "Global Reach:",
            points_para: " Delivers scalable solutions that grow with your business, sustaining expansion into new markets with excellence."
        },
    ];

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="services-benefits">
                    <div className="heading mx-1">Benefits</div>
                    {
                        points.map((point, index) => (
                            <div className="benefits-points d-flex">
                                <span className='bullet'><GoDotFill /></span>

                                <div className="points" key={index}>
                                    <span className='bold-text pe-1'>{point.bold_text}</span>
                                    {point.points_para}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default Benefits;
