import React, { useEffect } from 'react';
import { MdArrowOutward } from 'react-icons/md';
import video from '../../../Image/Cloud_video.mp4';
import img from '../../../Image/poster/cloud-service.jpg'

function Details() {
    useEffect(() => {
        const animatedRow = document.querySelector('.animation-row');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('scrolled');
                }
            });
        }, {
            threshold: 0.5
        });

        if (animatedRow) {
            observer.observe(animatedRow);
        }
        return () => {
            if (animatedRow) {
                observer.unobserve(animatedRow);
            }
        };
    }, []);


    return (
        <>
            <div className="container-fluid px-0 mx-0 mt-3 pt-2">
                <div className="development-services">
                    <div className="details-parabox">
                        <p className='details-para mb-4'>At Nasksoft, our Cloud & DevOps services enable businesses to improve efficiency and product quality through automation and smooth processes. We specialize in setting up CI/CD pipelines, infrastructure as code (IaC), and automation tools to reduce manual errors and help faster deployments. The secured cloud migration and management services ensure smooth transitions from conventional infrastructure to the cloud. We endure everything from migration planning to ongoing monitoring and optimization, ensuring peak performance, scalability, and seamless cloud integration for your business.  
                        </p>
                        {/* <p className='details-para mb-4'>  Our Cloud Migration & Management services assist businesses in transitioning from traditional infrastructure to the cloud. We handle every aspect of cloud integration, from migration planning to execution, ensuring that your data and applications are transferred securely and efficiently. Our team also provides ongoing cloud management, monitoring, and optimization to ensure that your systems run smoothly and at peak performance.</p> */}
                    </div>
                    <div className="row mx-0 mt-5 pt-2 animation-row">
                        <div className="col-lg-7 col-12 px-0 pb-md-0 pb-4">
                            <div className="services-imgbox">
                                <video poster={img} autoPlay={true} muted loop id="bgVideo">
                                    <source src={video} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                </video>
                                <div className="frame"></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 mt-lg-0 mt-4">
                            <div className="services-optionbox new-services-optionbox  mb-lg-0 mb-lg-5 mb-sm-4 mb-5">
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Infrastructure as Code (IaC) Setup</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>CI/CD Pipeline Configuration</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Automation Tool Integration</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Data Migration</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Application Migration</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Cloud Infrastructure Setup</div>
                                {/* <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Cloud Infrastructure Setup</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Details;
