import React from 'react'


function Banner() {
   

    return (
        <div className="container-fluid m-0 p-0">
            <div className="row m-0">
                <div className="mainframe-video">
                    <div className="video-frame"></div>
                    <div className="col-lg-8 col-12 px-4">
                        <div className="head-tag pb-3">About Us</div>
                        <p className="head-para mb-0 px-lg-5 px-0 py-0">
                        Associate with skilled IT professionals in web and mobile app development. As a trusted tech and business partner, Nasksoft utilises the latest technologies, frameworks, and tools to build cutting-edge software. From development, and branding to driving sales growth, our solutions are designed to help businesses scale efficiently.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
