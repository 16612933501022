import React, { useEffect, useState } from 'react';
import img from '../../Image/image (7).png';


function News() {
    const [newsData, setNewsData] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(`https://nasksoft.com/nasksoft/public/api/blogs-data`);
                const data = await response.json();
                const formattedData = data?.blog?.slice(2,5)?.map((article, index) => ({
                    id: index + 1,  // Generate a unique ID based on the index
                    title: article.heading,
                    heading: article.topic,
                    link: article.video_url,
                    author: article.author || "Unknown",  // Fallback if author is not available
                    date: new Date(article.created_at).toLocaleDateString(),  // Format date
                    img: article.thumbnail_img || img,  // Fallback image if not available
                    description: article.description || "No description available." // Fallback description
                }));
                setNewsData(formattedData);

            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchNews();
    }, []);  // Empty dependency array to run effect only once on mount

    return (
        <div className='news'>
            <div className='container-fluid my-5'>
            
                <h2>Insight & News</h2>
                <p>Stay Updated With Latest Corporate Insights</p>
                <div className='row'>
                    {newsData?.map((news) => (
                        <a  href={news?.link} target='_blank' style={{textDecoration:"none"}} key={news.id} className='col-lg-4 col-md-6 col-sm-6 mb-4'>
                            <div className='news-card px-1'>
                                <div className='card h-100'>
                                    <div style={{widthL:"100%",height:"200px"}}>
                                    <img style={{width:"100%",height:"100%",objectFit:"cover" , borderRadius:'5px'}} src={"https://nasksoft.com/nasksoft/public/"+news.img} className='card-img-top' alt={news.topic} />

                                    </div>
                                    <div className='card-body'>
                                        <h5 className='card-title'>{news.title}</h5>
                                        <div className='my-3'>
                                            <div className='d-flex author-details'>
                                                <h6>{news.author}</h6>
                                                <h6 style={{ marginLeft: '15px' }}>{news.date}</h6>
                                            </div>
                                            <hr className='mb-2 mt-0 my-2'/>
                                            <div className='dec'   dangerouslySetInnerHTML={{__html: news?.description}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default News;
