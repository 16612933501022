import React from 'react'
import img from '../../Image/Rectangle 1848 (2).png'
import img1 from '../../Image/Combined-Shape.png'
import img2 from '../../Image/Combined-Shape (1).png'
// import img3 from '../../Image/“.png'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import img4 from '../../Image/Mask group (1).png'
import img5 from '../../Image/Mask group (2).png'
import img6 from '../../Image/image 996 (1).png'
import { GrInstagram } from 'react-icons/gr'
function Banner() {
    return (
        <>
            <div className='container-fluid blog-singal p-0 px-5'>
                <div className='row m-0 px-2'>
                    <div className='col-lg-8 col-12 p-0 '>
                        <div className='singal-card mb-5'>
                            <div className='singal-img'>
                                <img style={{ width: "100%" }} src={img} alt='...' />
                            </div>
                            <div className='blog-span'>
                                <span><img src={img1} alt='...'/><h4>Admin</h4></span>
                                <span><img src={img2} alt='...'/><h4>No Comments</h4></span>
                            </div>
                            <h3>Meet PearAI: The Free, Open-SourceAlternative to Cursor AI</h3>
                            <p>Uniquely Matrix Economically Sound Value Through Cooperative Technology. Competently Parallel Task Fully Researched Data And Enterprise Process Improvements. Collaboratively Expedite Quality Manufactured Products Via Client-Focused Results Quickly Communicate Enabled Technology And Turnkey Leadership Skills. Uniquely Enable Accurate Supply Chains Rather Than Friction Technology.</p>
                            <h3>The Digital Service For All</h3>
                            <p>Appropriately Empower Dynamic Leadership Skills After Business Portals. Globally My Carminate Interactive Supply Chains With Distinctive Quality Vectors Global Sources Services. Uniquely Matrix Economically Sound Value Through Cooperative Technology. Competently Parallel Task Fully Researched Data And Enterprise Process Improvements.</p>
                            <div className='blog-li'>
                                <ul>
                                    <li>Listen to what they say about you</li>
                                    <li>Randomised words which.</li>
                                    <li>Internet tend to repeat predefined chunks</li>
                                </ul>
                            </div>
                            <div  className='testimonial'>
                                <div  className='test'>
                                    <div className='test-icon'>
                                        {/* <img src={img3} /> */}
                                    </div>
                                    <p>“For the longest time, computers have been associated with work. Mainframes were for the Army, government agencies, and then large companies. Workstations were for engineers and software programmers. PCs were initially for other white-collar jobs.”</p>
                                </div>
                            </div>
                            <p>Make real time a work improvements</p>
                            <p>Collaboratively Administrate Empowered Markets Via Plug-And-Play Networks. Dynamically Procrastinate B2C Users After Installed Base Benefits. Dramatically Visualize Customer Directed Convergence Without Revolutionary ROI.</p>
                            <p>1.It brings the right people together with all the right information and tools to get work done</p>
                            <p>2.We provide operational efficiency, data security, and flexible scale</p>
                            <p>3.Your best work, together in one package that works seamlessly from your computer</p>
                            <p>4.Delivers the tools you need to save time field performance always</p>
                            <div className='singal-div'>
                                <h4>Share</h4>
                                <div className='singal-icon'>
                                    <div><FaFacebookF /></div>
                                    <div><FaTwitter /></div>
                                    <div><FaLinkedinIn /></div>
                                    <div><BsInstagram /></div>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 p-2'>
                        <div className='singal-side-card p-0'>
                            <div className="input-group serch-card mb-3">
                                <input type="text" className="form-control" placeholder="Serch " aria-label="Serch" aria-describedby="basic-addon2" />
                                <span className="input-group-text" id="basic-addon2"><FaMagnifyingGlass /></span>
                            </div>
                          <div className='Recent-post p-4'>
                            <h3>Recent Post</h3>
                            <div className='recent-img '>
                               <div className='r-img'>
                               <img src={img4} alt='...' />
                               </div>
                                <div className='recent-heading'>
                                    <h5>How to Increase Your ROI Through scientific</h5>
                                    <h6>May 02, 2022       3 min read</h6>
                                </div>
                            </div>
                            <hr/>
                            <div className='recent-img my-4'>
                               <div className='r-img'>
                               <img src={img5} alt='...' />
                               </div>
                                <div className='recent-heading'>
                                    <h5>How to Increase Your ROI Through scientific</h5>
                                    <h6>May 02, 2022       3 min read</h6>
                                </div>
                            </div>
                            <hr/>
                            <div className='recent-img my-4'>
                               <div className='r-img'>
                               <img style={{borderRadius:'10px'}} src={img6} alt='...' />
                               </div>
                                <div className='recent-heading'>
                                    <h5>How to Increase Your ROI Through scientific</h5>
                                    <h6>May 02, 2022       3 min read</h6>
                                </div>
                            </div>
                          </div>
                          <div className='blog-categories'>
                            <h3>Categories</h3>
                            <div>
                            <h5>ALL</h5>
                            <h6>(7)</h6>
                            </div>
                            <div>
                            <h5>TECHNOLOGY</h5>
                            <h6>(3)</h6>
                            </div>
                            <div>
                            <h5>Business</h5>
                            <h6>(1)</h6>
                            </div>
                            <div style={{border:'none'}}>
                            <h5>Marketing</h5>
                            <h6>(2)</h6>
                            </div>
                          </div>
                          <div className='blog-follow'>
                            <h3>Follow Us</h3>
                            <div className='follow-icon'>
                            <div><FaFacebookF /></div>
                            <div><FaTwitter /></div>
                            <div><FaLinkedinIn /></div>
                            <div><GrInstagram /></div>
                            </div>
                          </div>
                          <div className='news-button'>
                            <button>View All News</button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner

