import React from 'react'
// import img from '../../../Images/c77ca5f309053fd7372ca5dfbd41437c.gif'

function Banner() {
  return (
    <div>
       <div className="container-fluid m-0 p-0">
            <div className="row m-0">
                <div className="mainframe-video">
                    <div className="video-frame"></div>
                    <div className="col-lg-8 col-12 px-4">
                        <div className="head-tag pb-3">Cloud & DevOps Services</div>
                        <p className="head-para mb-0 px-lg-5 px-0 py-0">
                        Accelerating Innovation with Scalable Cloud Solutions & DevOps Automation.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Banner
