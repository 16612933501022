// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Blogs from './Pages/Blogs';
// import NewNavBar from './Componets/Homepage/NewNavBar';
import Footer from './Componets/Homepage/Footer';
import AboutUs from './Pages/AboutUs';
import DevelopmentServices from './Pages/DevelopmentServices';
import DesignServices from './Pages/DesignServices';
import SeoServices from './Pages/SeoServices';
import QAServices from './Pages/QAServices';
import CloudServices from './Pages/CloudServices';
import EcommerceServices from './Pages/EcommerceServices';
import ContactUs from './Pages/Contact_us';
import Blogsingle from './Pages/Blogsingle';
import News from './Pages/News';
import NewsSingel from './Pages/NewsSingel';
import ScrollToTop from './Pages/ScrollToTop';
import Crareer from './Pages/Crareer';
// import DevelopmentServices from './Pages/DevelopmentServices';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { useEffect } from 'react';
function App() {
   // Initialize AOS when component mounts
   useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration (1 second)
      // once: true,      // Whether animation should happen only once
    });
    AOS.refresh();      // Refresh AOS to detect changes in the DOM
  }, []);
  return (
    <>
      <BrowserRouter>
      <ScrollToTop/>
      {/* <NewNavBar /> */}
        <Routes>
          <Route path='/'element={<HomePage/>}/>
          <Route path='/Blogs'element={<Blogs/>}/>
          <Route path='/Blogsingle'element={<Blogsingle/>}/>
          <Route path='/about-us' element={<AboutUs />}/>
          <Route path='/career' element={<Crareer />}/>
          <Route path='/development-services' element={<DevelopmentServices />}/>
          <Route path='/design-services' element={<DesignServices/>}/>
          <Route path='/seo-services' element={<SeoServices/>}/>
          <Route path='/quailty-services' element={<QAServices/>}/>
          <Route path='/cloud-services' element={<CloudServices/>}/>
          <Route path='/ecommerce-services' element={<EcommerceServices/>}/>
          <Route path='/contact-us' element={<ContactUs/>}/>
          <Route path='/news' element={<News/>}/>
          <Route path='/new-single' element={<NewsSingel/>}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
