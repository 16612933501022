import React, { useState } from 'react';
import img from '../../Image/Layer_6.svg';
import img1 from '../../Image/case_studio_images/psh-cover.webp';
import img2 from '../../Image/case_studio_images/home-wso.940c6826cb1a280a43a3.webp';
import img3 from '../../Image/case_studio_images/wdh-new-structure.f0777030712fd474d13b.webp';
import img4 from '../../Image/case_studio_images/home-home-pp.9fe6b82af5b4167055ca.webp';
import { RiArrowDownDoubleFill, RiArrowUpDoubleLine } from "react-icons/ri";


const caseStudies = [
  {
    title: "Photography Studio Hub",
    description: "Nasksoft provides businesses with a comprehensive array of photography and studio services designed to promote brand identity and represent products effectively. The platform has offered several services from e-commerce to product photography and portfolio shoots. The studio spaces and expert team deliver visually compelling imagery that captivates audiences and increases conversions. With an understanding of high-quality visuals in the digital domain, Nasksoft is dedicated to providing outstanding photography solutions through the Photography Studio Hub platform.",
    img: img,
    overlayImg: img1,
    id: 'photohub',
  },
  {
    title: "Wholesale Shopping",
    description: "Wholesale Shopping, a project developed by Nasksoft, is an innovative platform designed to enhance customer satisfaction and simplify bulk purchasing for retailers. Nasksoft provides end-to-end digital solutions, including digital marketing, branding, sales optimization, and e-commerce strategies customized for wholesale clients. With a focus on scalability and customer-centric design, Nasksoft has encouraged Wholesale Shopping to become a trusted hub for retailers, optimizing operations, expanding reach, and delivering an exceptional shopping experience.",
    img: img,
    overlayImg: img2,
    id: 'wholesale',
  },
  {
    title: "Web Digital Hub",
    description: "The successful launch of Nasksoft, Web Digital Hub, a digital services platform to offer digital Digital Marketing, Digital Design, and Development services to businesses. Nasksoft employs its expertise in web and mobile app development to enhance operations with the integration of shopping carts and payment gateway. Web Digital Hub provides IT consulting services to optimize and secure infrastructure. The SEO and social media strategies help businesses to boost online visibility. Web Digital Hub offers businesses to achieve remarkable results with expert digital solutions",
    img: img,
    overlayImg: img3,
    id: 'web',
  },
  {
    title: "Pakistan Property",
    description: "Nasksoft has delivered its expert digital solutions with Pakistan Property, a platform developed to facilitate property searches and real estate transactions. The platform enables users to easily explore, buy, and rent properties across Pakistan through its intuitive interface designed with Nasksoft custom web development and IT services. The platform features refined search filters, user-friendly listings and secure integration, offering a user-friendly interface to buyers and sellers. Nasksoft solutions has improved the Pakistan Property platform to enhance operational efficiency.",
    img: img,
    overlayImg: img4,
    id: 'property',
  },
];

function Latest() {
  // Track which descriptions are expanded
  const [expanded, setExpanded] = useState({});

  // Toggle the expanded state of a specific case study
  const toggleExpanded = (index) => {
    setExpanded(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <div id='case_study' className='container-fluid my-5'>
      <div className='latest-case mt-4'>
        <h4>Check Out Our Latest Work</h4>
        <p>Don’t take our word for it, let our work do the talking.</p>
        <div className='row m-0' style={{ flexWrap: 'wrap' }}>
          {caseStudies.map((caseStudy, index) => (
            <div className='col-lg-6 col-md-6 col-12 mt-sm-0 mt-4' key={index}>
              <div className="latest-card">
                <div className="img laptop-img">
                  <div className='Laptop'>
                    <img src={caseStudy.img} alt="..." />
                    <div className="img1">
                      <img id={caseStudy.id} src={caseStudy.overlayImg} alt="..." />
                    </div>
                  </div>
                </div>
                <div className='latest-h mt-sm-0 mt-3'>
                  <h3>{caseStudy.title}</h3>
                  <p className={`Ser_para ${!expanded[index] ? 'truncated-text' : ''} pe-md-5`}>
                    {caseStudy.description}
                  </p>
                  <div className='d-flex justify-content-center pe-md-5' style={{ flexDirection: 'column' }}>
                    <hr className='readmore_hr mt-0' />
                    <button
                      className="btn btn-link w-100 read-more_btn"
                      onClick={() => toggleExpanded(index)}
                    >
                      {expanded[index] ? (
                        <span>
                          Read Less <RiArrowUpDoubleLine />
                        </span>
                      ) : (
                        <span>
                          Read More <RiArrowDownDoubleFill />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Latest;
