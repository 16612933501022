import React, { useState } from "react";
// import img from "../../Image/Group 1000014118 (1).png";
// import { FaArrowRight, FaLocationDot } from "react-icons/fa6";
import { GoArrowRight } from "react-icons/go";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import pin from "../../Image/Group.svg";
import pak from "../../Image/pak.png";
import dropIcon from "../../Image/Frame 1300192433.svg";
function Data() {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    contact: "",
    budget: "",
    businessDesc: "",
  });
  const handleClick = () => {
    setOpen(true);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const [errors, setFieldErrors] = useState({});

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateStep = () => {
    let errors = {};

    if (!formData.firstName) {
      errors.firstName = "First Name is required.";
    }

    if (!formData.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!formData.contact) {
      errors.contact = "Contact is required.";
    }

    if (!formData.budget) {
      errors.budget = "Estimated Budget is required";
    }
    if (!formData.businessDesc) {
      errors.businessDesc = "Business Description is required.";
    }

    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep()) {
      setFieldErrors({});
      handleFormData();
      setFormData({
        firstName: "",
        email: "",
        contact: "",
        budget: "",
        businessDesc: "",
      });
    }
  };
  const [selectedOption, setselectedOption] = useState([]);
  const handleOption = (value) => {
    if (selectedOption.includes(value)) {
      setselectedOption(selectedOption.filter((option) => option !== value));
    } else {
      setselectedOption([...selectedOption, value]);
    }
  };
  const [selectedOpt, setselectedOpt] = useState();
  const handleOpt = (value) => {
    if (value === selectedOpt) {
      setselectedOpt("");
    } else {
      setselectedOpt(value);
    }
  };
  const [message, SetMessage] = useState({
    message: "",
    color: "",
  });
  const handleFormData = () => {
    const obj = {
      full_name: formData.firstName,
      email: formData.email,
      phone_number: formData.contact,
      estimated_budget: formData.budget,
      message: formData.businessDesc,
      contact_nature: selectedOpt,
      service_name: selectedOption?.join(","),
    };

    fetch("https://nasksoft.com/nasksoft/public/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.message === "Contact Form Submit Successfully") {
          handleClick();
          SetMessage({
            message: "Request Sent Successfully",
            color: "success",
          });
        } else {
          handleClick();
          SetMessage({
            message: "Request Sent Unsuccessfully",
            color: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container-fluid data">
      <div className="row px-sm-3 m-0">
        <div className="col-lg-6 p-0">
          <div className="tell-us">
            <h3 className="w-100 text-lg-start text-center">
            Let’s Discuss Your 
              <span className="w-100 text-lg-start text-center">
                <br />
                Development Needs
              </span>
            </h3>
            <p className="text-lg-start text-center">
              Reach out to discuss your ideas with our team, ready to develop solutions
              customised to your needs. Whatever your goals, we’re here to make them
              happen.
            </p>
          </div>
        </div>
        <div className="col-lg-6 p-0">
          <form onSubmit={handleSubmit} className="data-card">
            <h4 className="pe-4 ps-lg-0 ps-2 text-lg-start text-center">Describe Your Business & Initiate Project Today</h4>
            <h6 className="mb-0">I am looking to</h6>
            <div className="white-space">
              <div className="redious-card">
                <button
                  className={`${selectedOpt?.includes("Start a new Project")
                      ? "selected_option-btn"
                      : "redious-card-btn"
                    }`}
                  onClick={() => handleOpt("Start a new Project")}
                  type="button"
                >
                  Start a new Project
                </button>
                <button
                  className={`${selectedOpt?.includes("Revamp Existing Development")
                      ? "selected_option-btn"
                      : "redious-card-btn"
                    }`}
                  onClick={() => handleOpt("Revamp Existing Development")}
                  type="button"
                >
                  Revamp Existing Development
                </button>
                <button
                  className={`${selectedOpt?.includes("Consultation")
                      ? "selected_option-btn"
                      : "redious-card-btn"
                    }`}
                  onClick={() => handleOpt("Consultation")}
                  type="button"
                >
                  Consultation
                </button>
                <button
                  className={`${selectedOpt?.includes("Dedicated Team")
                      ? "selected_option-btn"
                      : "redious-card-btn"
                    }`}
                  onClick={() => handleOpt("Dedicated Team")}
                  type="button"
                >
                  Dedicated Team
                </button>
              </div>

              {/* Service Section */}
              <div style={{ marginTop: "42px" }} className="dat-car">
                <h6 className="mb-0">Service I need</h6>
                <div className="redious-card">
                  <button
                    className={`${selectedOption?.includes(
                      "Consulting & Digital Transformation"
                    )
                        ? "selected_option-btn"
                        : "redious-card-btn"
                      }`}
                    onClick={() =>
                      handleOption("Consulting & Digital Transformation")
                    }
                    type="button"
                  >
                    Consulting & Digital Transformation
                  </button>
                  <button
                    className={`${selectedOption?.includes("Web Development")
                        ? "selected_option-btn"
                        : "redious-card-btn"
                      }`}
                    onClick={() => handleOption("Web Development")}
                    type="button"
                  >
                    Web Development
                  </button>
                  <button
                    className={`${selectedOption?.includes("E-commerce Solutions")
                        ? "selected_option-btn"
                        : "redious-card-btn"
                      }`}
                    onClick={() => handleOption("E-commerce Solutions")}
                    type="button"
                  >
                    E-commerce Solutions
                  </button>
                  <button
                    className={`${selectedOption?.includes("Product Design")
                        ? "selected_option-btn"
                        : "redious-card-btn"
                      }`}
                    onClick={() => handleOption("Product Design")}
                    type="button"
                  >
                    Product Design
                  </button>

                  <button
                    className={`${selectedOption?.includes("Shopify Development")
                        ? "selected_option-btn"
                        : "redious-card-btn"
                      }`}
                    onClick={() => handleOption("Shopify Development")}
                    type="button"
                  >
                    Shopify Development
                  </button>
                  <button
                    className={`${selectedOption?.includes("UI UX Design")
                        ? "selected_option-btn"
                        : "redious-card-btn"
                      }`}
                    onClick={() => handleOption("UI UX Design")}
                    type="button"
                  >
                    UI UX Design
                  </button>
                  <button
                    className={`${selectedOption?.includes("Mobile Development")
                        ? "selected_option-btn"
                        : "redious-card-btn"
                      }`}
                    onClick={() => handleOption("Mobile Development")}
                    type="button"
                  >
                    Mobile Development
                  </button>
                  <button
                    className={`${selectedOption?.includes("QA Testing & Automation")
                        ? "selected_option-btn"
                        : "redious-card-btn"
                      }`}
                    onClick={() => handleOption("QA Testing & Automation")}
                    type="button"
                  >
                    QA Testing & Automation
                  </button>

                  <button
                    className={`${selectedOption?.includes("Cloud & DevOps Services")
                        ? "selected_option-btn"
                        : "redious-card-btn"
                      }`}
                    onClick={() => handleOption("Cloud & DevOps Services")}
                    type="button"
                  >
                    Cloud & DevOps Services
                  </button>
                </div>
              </div>
            </div>
            <div className="row m-0 d-flex justify-content-lg-start justify-content-center">
              <div className="col-lg-6 col-md-10 col-12 mt-3 px-md-3 px-sm-3 px-2">
                <div className="contect_input">
                  <label>Full Name</label>
                  <input
                    type="text"
                    value={formData?.firstName}
                    name="firstName"
                    onChange={handleInputChange}
                    placeholder="Full Name"
                  />
                  <span
                    className="text-danger"
                    style={{
                      marginBottom: "3px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {errors?.firstName}
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-md-10 col-12 mt-3 px-md-3 px-sm-3 px-2">
                <div className="contect_input">
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    value={formData?.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                  />
                  <span
                    className="text-danger"
                    style={{
                      marginBottom: "5px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {errors?.email}
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-md-10 col-12 mt-3 px-md-3 px-sm-3 px-2">
                <div className="contect_input" style={{ position: "relative" }}>
                  <label>Contact</label>{" "}
                  <div className="contact_contry d-flex">
                    <div className="img_container">
                      <img src={pak} alt="..." />
                      <span>+92</span>
                      <img src={dropIcon} alt="" />
                    </div>
                    <input
                      style={{
                        borderRadius: " 0 5px 5px 0 ",
                        borderLeft: "none",
                      }}
                      name="contact"
                      maxLength={10}
                      value={formData?.contact}
                      onChange={handleInputChange}
                      type="number"
                      placeholder="Contact"
                    />
                  </div>
                  <span
                    className="text-danger"
                    style={{
                      marginBottom: "5px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {errors?.contact}
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-md-10 col-12 mt-3 px-md-3 px-sm-3 px-2">
                <div className="contect_input" style={{ position: "relative" }}>
                  <label>Estimated Budget</label>

                  <input
                    type="text"
                    name="budget"
                    value={formData?.budget}
                    onChange={handleInputChange}
                    placeholder="Your Budget"
                  />

                  <span
                    className="text-danger"
                    style={{
                      marginBottom: "5px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {errors?.budget}
                  </span>
                </div>
              </div>
              <div className="col-lg-12 col-md-10 col-12 mt-3 ps-sm-3 pe-sm-4 px-2">
                <div className="contect_input" style={{ position: "relative" }}>
                  <label>Describe your Business</label>
                  <textarea
                    type="text"
                    name="businessDesc"
                    value={formData?.businessDesc}
                    onChange={handleInputChange}
                    placeholder="Write here"
                  ></textarea>
                  <span
                    className="text-danger"
                    style={{ fontSize: '13px', fontWeight: '500' }}
                  >
                    {errors?.businessDesc}
                  </span>
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={handleSubmit}
                className="apply-btn-1"
              >
                <div className="animation-frame"></div>
                <div className="sign">
                  <GoArrowRight />
                </div>
                <div className="text">Send us a message</div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="how">
        <h3>Contact Us</h3>
        <p>
        Let’s build innovative digital solutions to scale your business.</p>
      </div>
      <div className="show_box d-none">
        <div className="m-map">
          <div className="row m-0">
            <div className="col-md-4 col-12 p-0">
              <div className="map-heading new_map_heading">
                <p>Phone</p>
                <span className="d-flex align-items-baseline mb-md-0 mb-3">
                  <FaPhoneAlt />
                  <p style={{ marginLeft: "10px" }}>+92 305 1115551</p>
                </span>
                <p>
                  <p style={{ color: "#00293E" }}>Email:</p>
                  <span className="d-flex align-items-baseline mb-md-0 mb-3">
                    <MdEmail />
                    <p style={{ marginLeft: "10px" }}>contact@nasksoft.com</p>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="col-md-8 col-12 location_col"
              style={{ paddingLeft: "6rem" }}
            >
              <p className="map-heading">
                <h5>Pakistan Office Address</h5>
                <span className="d-flex align-items-baseline">
                  {/* <FaLocationDot fontSize={'25px'} /> */}
                  <img src={pin} alt="" />
                  <p style={{ marginLeft: "10px" }}>
                    Building No. 21, 3rd Floor, Block-CCA, DHA Phase 8 - Ex Park
                    View Phase-8 Lahore, 54000.
                  </p>
                </span>
              </p>
              <p className="map-heading">
                <h5>United Kingdom Office Address</h5>
                <span className="d-flex align-items-baseline">
                  {/* <FaLocationDot fontSize={'20px'} /> */}
                  <img src={pin} alt="" />
                  <p style={{ marginLeft: "10px" }}>
                    74H Sherborne Street, Manchester, United Kingdom
                  </p>
                </span>
              </p>
            </div>
          </div>
          <div></div>
        </div>
        <div className="d-flex justify-content-center w-100">
          <a
            style={{ textAlign: "center", textDecoration: "none" }}
            href="https://maps.app.goo.gl/nwytkdEYewKQCTYNA"
            className="apply-btn mt-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="animation-frame-1 animation-frame"></div>
            <div className="sign">
              <GoArrowRight />
            </div>
            <div className="text">View on Map</div>
          </a>
        </div>
      </div>

      {/* Google Map Section */}
      <div style={{ position: "relative", width: "100%" }}>
        {/* Fullscreen Google Map */}
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345094315!2d144.95592341531647!3d-37.81720997975132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577f2a99aa4f6b5!2sVictoria%20State%20Library!5e0!3m2!1sen!2sau!4v1613973318123!5m2!1sen!2sau"
          width="100%"
          height="460px"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Map"
        ></iframe> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13604.362497224101!2d74.42700851609969!3d31.521670828933576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190f0edb1ad977%3A0xf6b165821d6a6dc!2sDHA%20Phase%208%20-%20Ex%20Park%20View%20Park%20View%20CHS%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1722356405588!5m2!1sen!2s" width="100%" height="460px" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='location'></iframe>

        {/* Contact Info Card */}
        <div
          style={{ position: "absolute", top: "10%" }}
          className="d-flex justify-content-center w-100"
        >
          <div
            className="contact-card"
            style={{
              width: "880px",
              padding: "20px 50px",
              backgroundColor: "white",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="hide_box">
              <div className="m-map">
                <div className="row m-0">
                  <div className="col-md-4 col-12 p-0">
                    <div className="map-heading new_map_heading">
                      <p>Phone</p>
                      <span className="d-flex align-items-baseline mb-md-0 mb-3">
                        <FaPhoneAlt />
                        <p style={{ marginLeft: "10px" }}>+92 305 1115551</p>
                      </span>
                      <p>
                        <p style={{ color: "#00293E" }}>Email:</p>
                        <span className="d-flex align-items-baseline mb-md-0 mb-3">
                          <MdEmail />
                          <p style={{ marginLeft: "10px" }}>
                            contact@nasksoft.com
                          </p>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-8 col-12 location_col"
                    style={{ paddingLeft: "6rem" }}
                  >
                    <p className="map-heading">
                      <h5>Pakistan Office Address</h5>
                      <span className="d-flex align-items-baseline">
                        {/* <FaLocationDot fontSize={'25px'} /> */}
                        <img src={pin} alt="" />
                        <p style={{ marginLeft: "10px" }}>
                          Building No. 21, 3rd Floor, Block-CCA, DHA Phase 8 -
                          Ex Park View Phase-8 Lahore, 54000.
                        </p>
                      </span>
                    </p>
                    <p className="map-heading">
                      <h5>United Kingdom Office Address</h5>
                      <span className="d-flex align-items-baseline">
                        {/* <FaLocationDot fontSize={'20px'} /> */}
                        <img src={pin} alt="" />
                        <p style={{ marginLeft: "10px" }}>
                          74H Sherborne Street, Manchester, United Kingdom
                        </p>
                      </span>
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="d-flex justify-content-center w-100">
                <a
                  style={{ textAlign: "center", textDecoration: "none" }}
                  href="https://maps.app.goo.gl/nwytkdEYewKQCTYNA"
                  className="apply-btn mt-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="animation-frame-1 animation-frame"></div>
                  <div className="sign">
                    <GoArrowRight />
                  </div>
                  <div className="text">View on Map</div>
                </a>
              </div>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={message?.color}
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {message?.message}
                </Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Data;
