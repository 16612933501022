import React from 'react'
import CareerBanner from '../Componets/Careers/Careers-offeredjobs/CareerBanner'
import CareerNoData from '../Componets/Careers/Carrers-Notfound/CareerNoData'
// import CareerData from '../Componets/Careers/Careers-offeredjobs/CareerData'
// import PicSection from '../Componets/Careers/Careers-offeredjobs/PicSection'
import NewNavBar from '../Componets/Homepage/NewNavBar'

function Crareer() {
  return (
    <>
    <NewNavBar/>
    <CareerBanner/>
    <CareerNoData/>
    {/* <CareerData/> */}
    {/* <PicSection/>s */}
    </>
      )
}

export default Crareer