import React from 'react';
import { GoDotFill } from 'react-icons/go';

function Benefits() {

    const points = [
        {
            bold_text: "Enhanced User Engagement:",
            points_para: "Digital design services enhance user experiences through intuitive interfaces, enabling better engagement with your brand or product."
        },
        {
            bold_text: "Brand Consistency:",
            points_para: "We ensure a suitable visual language across all platforms, strengthening brand identity and distinction in the marketplace."
        },
        {
            bold_text: "Efficiency:",
            points_para: "Our services facilitate workflows to reduce production times and costs, ensuring faster project completion without compromising on quality."
        },
        {
            bold_text: "Scalability:",
            points_para: "Our Digital design services adapt efficiently to business growth, subsidising expansion across diverse platforms and audience demands."
        },
        {
            bold_text: "Flexibility & Scalability:",
            points_para: "The innovative services at Nasksoft allow for immediate updates and changes, ensuring your design remains applicable in fast-evolving markets"
        },
        {
            bold_text: "Enhanced Communication:",
            points_para: "Visual storytelling through our digital design services transmits complex visions effectively, making information more effortless to understand."
        },
        {
            bold_text: "Cross-platform Compatibility:",
            points_para: "Our strategic approach ensures your designs look prominent on all devices, improving accessibility and user satisfaction."
        },
        {
            bold_text: "Mobile-Optimized Designs:",
            points_para: "Our responsive designs ensure your digital products look and perform well on any screen, and size including mobile devices while maximizing reach."
        },
    ];

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="services-benefits">
                    <div className="heading mx-1">Benefits</div>
                    {
                        points.map((point, index) => (
                            <div className="benefits-points d-flex">
                                <span className='bullet'><GoDotFill /></span>

                                <div className="points" key={index}>
                                    <span className='bold-text pe-1'>{point.bold_text}</span>
                                    {point.points_para}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default Benefits;
