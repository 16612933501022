import React from 'react'
import Details from '../Componets/Services/Development-Services/Details'
import Banner from '../Componets/Services/Development-Services/Banner'
import Benefits from '../Componets/Services/Development-Services/Benefits'
import OurTools from '../Componets/Services/Development-Services/OurTools'
import DevelopmentProcess from '../Componets/Services/Development-Services/DevelopmentProcess'
import NewNavBar from '../Componets/Homepage/NewNavBar'

function DevelopmentServices() {
  return (
    <>
    <NewNavBar/>
    <Banner/>
     <Details/>
     <Benefits/>
     <DevelopmentProcess/>
     <OurTools/>
    </>
  )
}

export default DevelopmentServices
