import React from 'react'

function Blog() {
  return (
    <>
    <div className="container-fluid m-0 p-0">
            <div className="row m-0">
                <div className="mainframe-video">
                    <div className="video-frame"></div>
                    <div className="col-lg-10 col-12 px-4">
                        <div className="head-tag pb-3">Blogs</div>
                        <p className="head-para mb-0 px-lg-5 px-0 py-0">
                        NaskSoft can enhance the efficiency of businesses through digital technology. NaskSoft offers a full range of solutions for e-commerce businesses from branding to sales growth. We are providing out-of-the-box solutions to the business community. We are committed to customer satisfaction and delivering excellence.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Blog
