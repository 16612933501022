import React from 'react';
import { GoArrowRight } from 'react-icons/go';
import { Link } from 'react-router-dom';

function Project() {
  return (
    
    
    <>
      <div  className="container-fluid p-0">
        <div className="project">
          <div className="project-heading">
            <h4>SAY HELLO</h4>
            <h3>Let’s talk about your project <br /> Let’s make something great together!</h3>
            <p className='m-0 px-3'>
            Share your project vision with us to receive expert insights, actionable feedback, <br /> and suitable engagement options from our leadership expertise
            </p>
            <Link to={'/contact-us'} style={{textDecoration:"none"}}>
            <div style={{margin:'auto'}} className="apply-btn mt-2">
              <div className="animation-frame"></div>
              <div className="sign"><GoArrowRight /></div>
              <div style={{color:'white'}} className="text">Lets Talk</div>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Project;
