import React from 'react'
import Deslider from './Deslider'

function Crafting() {
  return (
    <>
    <div  className='container-fluid p-0'>
        <div  className='crafting'>
            <div style={{width:"90%",margin:"auto"}}>
            <h3>Strategic Planning, Innovative <br /> Development, Seamless Execution</h3>
            <p>Our deep expertise and strategic planning let us deliver customised, cutting-edge technology that is up to the mark of today’s digital-first world and lasting success.</p>
            </div>
           <Deslider/>
           <div id='insight_box'></div>
        </div>
    </div>
    </>
    
  )
}

export default Crafting
