import React, { useState, useEffect } from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import img from '../../Image/new-about-video.mp4';
import { GoArrowRight } from 'react-icons/go';
import img1 from '../../Image/new_about-us_posture.webp';
import { Link } from 'react-router-dom';
function About_us() {
  const [scrollEffect, setScrollEffect] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 750) {
        setScrollEffect(true);
      } else {
        setScrollEffect(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className='container-fluid'>
      <div className='about'>
        <p>We focused on empowering businesses by creating custom software solutions and driving digital transformation strategies.</p>
      </div>
      <div style={{ position: 'relative', paddingBottom: '50px' }} className='row m-0'>
        <div className='row about_mobile p-0 m-0'>
          <div className='col-lg-6 col-md-12 col-12'>
          <div className={`heading-side`}>
            <div className='icon-h d-flex align-items-center'>
              <div className={`me-2`}>
                <FiArrowUpRight />
              </div>
              <h3>
              Guiding Your Business Through Digital Success

              </h3>
            </div>
            <p className={`p mt-3`}>
            Nasksoft is the leading provider of software solutions and digital marketing services. Our assertive approach begins with a thorough understanding of your challenges, goals and industry requirements. We recognize that every business has its unique speciality and so does technology. We directly collaborate with you to develop customized digital solutions that effectively deliver tangible results and perfectly align with your pursuits. Our strategic team of experts developed solutions including responsive websites, and custom-built software solutions or feature-rich mobile apps that are functional, user-friendly, and scalable for your business growth.<br/>
              We offer more than prompt solutions but focus on delivering long-term solutions that grow alongside your business as the market requirements evolve. Alongside the development, we ensure seamless integration with the existing system, enhancing efficiency without causing disruption. Dedicated to delivering exceptional service, the company builds long-term relationships through customer satisfaction and supporting technological advancement for sustained business success.
            </p>
          </div>
          </div>
          <div  className='col-lg-6 col-md-12 col-12'>
          <div style={{position:"relative",height:"500px"}}  id='homepage_about_video' className='video'>
            <video  poster={img1}  style={{ height: '100%', objectFit: 'cover',position:"absolute" }} className='img-fluidx' autoPlay={true} muted loop id="bgVideo2">
              <source src={img} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          <div className='over_lay'></div>
          </div>
          </div>
        </div>
        <div className='row about_dec'>

        <div className={`col-lg-6 col-md-12 ps-lg-5 effect ${scrollEffect ? 'display' : ''}`}>
          <div className={`heading-side ps-5 ${scrollEffect ? '' : ''}`}>
            <div className='icon-h d-flex align-items-center'>
              <div className={`me-2 ${scrollEffect ? 'scroll_effect' : ''}`}>
                <FiArrowUpRight />
              </div>
              <h3 className={scrollEffect ? 'scroll_effect' : ''}>
              Guiding Your Business Through Digital Success
              </h3>
            </div>
            <p className={` p ${scrollEffect ? 'scroll_effect' : ''} mt-3`}>
            Nasksoft is the leading provider of software solutions and digital marketing services. Our assertive approach begins with a thorough understanding of your challenges, goals and industry requirements. We recognize that every business has its unique speciality and so does technology. We directly collaborate with you to develop customized digital solutions that effectively deliver tangible results and perfectly align with your pursuits. Our strategic team of experts developed solutions including responsive websites, and custom-built software solutions or feature-rich mobile apps that are functional, user-friendly, and scalable for your business growth.<br/>
            We offer more than prompt solutions but focus on delivering long-term solutions that grow alongside your business as the market requirements evolve. Alongside the development, we ensure seamless integration with the existing system, enhancing efficiency without causing disruption. Dedicated to delivering exceptional service, the company builds long-term relationships through customer satisfaction and supporting technological advancement for sustained business success.
            </p>
          </div>
        </div>
        <div className='col-lg-6 big-img col-md-12 px-lg-5'>
          <div  style={{position:"relative"}} className={`video right-img ${scrollEffect ? 'move_effect' : ''}`}>
            <video  poster={img1}  style={{ height: '100%', objectFit: 'cover',position:"absolute" }} className='img-fluidx' autoPlay={true} muted loop id="bgVideo2">
              <source src={img} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          <div className='over_lay'></div>

          </div>
        </div>
        <div className='col-lg-6'>
          <div className={`right-downc ${scrollEffect ? 'scroll_animation' : ''}`}>
            <div className='icon-h d-flex align-items-center'>
              <div style={{ marginLeft: '10px' }} className={`me-3 ${scrollEffect ? 'scroll_effect' : ''}`}>
                <FiArrowUpRight />
              </div>
              <h3 className={`scroll_effect`}>
              Scalable Solutions for <br /> Sustainable Growth
              </h3>
            </div>
            <div className='scroll-box'>
            <p style={{ marginLeft: '12px' }} className={scrollEffect ? 'scroll_effect' : ''}>
            We design technology systems that evolve with businesses, offering flexible, scalable solutions customised to meet varying requirements. From e-commerce platforms to cloud services, we ensure your infrastructure can handle increased demand while maintaining peak performance.
            </p>
            </div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className={`left-downc ${scrollEffect ? 'scroll_animation' : ''}`}>
            <div className='icon-h d-flex align-items-center'>
              <div style={{ marginLeft: '10px' }} className={`me-3 ${scrollEffect ? 'scroll_effect' : ''}`}>
                <FiArrowUpRight />
              </div>
              <h3 className={`scroll_effect`}>
              Building Partnerships for <br /> Enduring Success
              </h3>
            </div>
            <div className='scroll-box'>
            <p style={{ marginLeft: '12px' }} className={scrollEffect ? 'scroll_effect' : ''}>
            We’re more than just a technology provider, we aspire to build long-term partnerships with our clients. As your trusted IT partner, we provide continuous support, innovation, and maintenance, assuring your business remains ahead of the curve and thrives well into the future.
            </p>
           <Link to='/about-us' style={{textDecoration:'none'}}>
           <div style={{marginLeft:'10px'}} className="apply-btn mt-2 mb-3">
              <div className="animation-frame"></div>
              <div className="sign"><GoArrowRight /></div>
              <div  className="text">Learn More</div>
            </div>
           </Link>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default About_us;
