import React from 'react'
import Banner from '../Componets/Services/Design-Services/Banner'
import Details from '../Componets/Services/Design-Services/Details'
import Benefits from '../Componets/Services/Design-Services/Benefits'
import DeveloperProcess from '../Componets/Services/Design-Services/DeveloperProcess'
import OurTools from '../Componets/Services/Design-Services/OurTools'
import NewNavBar from '../Componets/Homepage/NewNavBar'

function DesignServices() {
  return (
    <>
    <NewNavBar/>
     <Banner/> 
     <Details/>
     <Benefits/>
     <DeveloperProcess/>
     <OurTools/>
    </>
  )
}

export default DesignServices
