import React from 'react';
import { GoDotFill } from 'react-icons/go';

function Benefits() {

    const points = [
        {
            bold_text: "Increased Efficiency:",
            points_para: "Combining DevOps with cloud services, we simplify processes, encouraging faster development cycles and lowering operational overheads."
        }, 
        {
            bold_text: "Scalability:",
            points_para: "Our Cloud infrastructure paired with DevOps qualifies businesses to scale resources up or down based on demand seamlessly."
        }, 
        {
            bold_text: "Faster Time to Market:",
            points_para: "We automated DevOps techniques to accelerate product deployment, helping businesses launch services and updates quicker than competitors."
        }, 
        { 
            bold_text: "Cost Efficiency:",
            points_para: "The cloud resources at Nasksoft are optimized, reducing excessive expenses, while automation minimizes costly errors and rework."
        }, 
        {
            bold_text: "Improved Collaboration:",
            points_para: "Our DevOps team encourages collaboration between development and operations, improving project alignment and delivery speed"
        }, 
        {
            bold_text: "Enhanced Security:",
            points_para: "The cloud-based DevOps solutions at Nasksoft integrate security measurements, safeguarding applications and data during deployment and scaling."
        },
    ]; 

    return (
        <>
            <div className="container-fluid m-0 p-0 mb-4 pb-1">
                <div className="services-benefits">
                    <div className="heading mx-1">Benefits</div>
                    {
                        points.map((point, index) => (
                            <div className="benefits-points d-flex">
                                <span className='bullet'><GoDotFill /></span>

                                <div className="points" key={index}>
                                    <span className='bold-text pe-1'>{point.bold_text}</span>
                                    {point.points_para}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default Benefits;
