import React, { useEffect } from 'react';
import { MdArrowOutward } from 'react-icons/md';
import video from '../../../Image/development_video.mp4';
import img from '../../../Image/poster/development-service.jpg'

function Details() {
    useEffect(() => {
        const animatedRow = document.querySelector('.animation-row');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('scrolled');
                }
            });
        }, {
            threshold: 0.5
        });

        if (animatedRow) {
            observer.observe(animatedRow);
        }
        return () => {
            if (animatedRow) {
                observer.unobserve(animatedRow);
            }
        };
    }, []);

    return (
        <>
            <div className="container-fluid px-0 mx-0 mt-3 pt-2">
                <div className="development-services">
                    <div className="details-parabox">
                        <p className='details-para mb-4'>
                        Acquire custom-made excellence with Nasksoft end-to-end development services. Our dedicated development teams specialize in delivering tailored solutions on time, ensuring specialised expertise, robust quality assurance, and cost efficiency. Whether you require mobile or web applications, cross-platform development, or full-stack services, we offer it to startups and enterprises alike. From concept to completion, we provide personalized software development that satisfies your distinctive requirements, guaranteeing a seamless experience and outstanding results.
                        </p>
                        {/* <p className='details-para'>
                            We collaborate with you to develop strong, purpose-built Web / Mobile Applications that are user-centered, mobile-friendly, and extremely intuitive using our 360-degree approach for Products. We can assist you in developing robust dynamic Web / Mobile Applications that work well for both users and search engines. We collaborate with you to develop strong, purpose-built Web / Mobile Applications that are user-centered, mobile-friendly, and extremely intuitive using our 360-degree approach for Products. We can assist you in developing robust dynamic Web / Mobile Applications that work well for both users and search engines.
                        </p> */}
                    </div>
                    <div className="row mx-0 mt-5 pt-2 animation-row">
                        <div className="col-lg-7 col-12 px-0 pb-md-0 pb-4">
                            <div className="services-imgbox">
                                <video poster={img} autoPlay={true} muted loop id="bgVideo">
                                    <source src={video} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                </video>
                                <div className="frame"></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 mt-lg-0 mt-4">
                            <div className="services-optionbox">
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Web Development</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Mobile App Development</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Custom Software Development</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Shopify Development</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Details;
