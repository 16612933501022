import React, { useEffect } from 'react';
import { MdArrowOutward } from 'react-icons/md';
import video from '../../../Image/design_video.mp4';
import img from '../../../Image/poster/design-service.jpg'

function Details() {
    useEffect(() => {
        const animatedRow = document.querySelector('.animation-row');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('scrolled');
                }
            });
        }, {
            threshold: 0.5
        });

        if (animatedRow) {
            observer.observe(animatedRow);
        }
        return () => {
            if (animatedRow) {
                observer.unobserve(animatedRow);
            }
        };
    }, []);

    return (
        <>
            <div className="container-fluid px-0 mx-0 mt-3 pt-2">
                <div className="development-services">
                    <div className="details-parabox">
                        <p className='details-para mb-4'>
                        The expert Digital Design services at Nasksoft dedicatedly deliver visually stunning and creative solutions that help businesses establish an assertive visual identity and user-centric experience. Through our expansive digital design service range including website design and branding elements, we ensure creatively functional and user-centric design to make your brand stand out in the digital domain. We meticulously pay attention to details to comprehend your brand’s value and ensure that every design reflects your vision and identity.
                        </p>
                        {/* <p className='details-para'>
                            We collaborate with you to develop strong, purpose-built Web / Mobile Applications that are user-centered, mobile-friendly, and extremely intuitive using our 360-degree approach for Products. We can assist you in developing robust dynamic Web / Mobile Applications that work well for both users and search engines. We collaborate with you to develop strong, purpose-built Web / Mobile Applications that are user-centered, mobile-friendly, and extremely intuitive using our 360-degree approach for Products. We can assist you in developing robust dynamic Web / Mobile Applications that work well for both users and search engines.
                        </p> */}
                    </div>
                    <div className="row mx-0 mt-5 pt-2 animation-row">
                        <div className="col-lg-7 col-12 px-0 pb-md-0 pb-4">
                            <div className="services-imgbox">
                                <video poster={img} autoPlay={true} muted loop id="bgVideo">
                                    <source src={video} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                </video>
                                <div className="frame"></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 mt-lg-0 mt-sm-5 mt-4">
                            <div className="services-optionbox">
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Product Design</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>UI / UX Design</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Mobile App Design</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Brand Design</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Details;
