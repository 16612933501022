import React from 'react'
import { GoDot } from 'react-icons/go'
import dev from '../../../Image/QA-services/Frame 1300193274.svg'
import dev1 from '../../../Image/QA-services/Frame 1300193284.svg'
import dev2 from '../../../Image/QA-services/Online report-pana 1.svg'
import dev4 from '../../../Image/QA-services/freepik--character-2--inject-6.svg'
import dev3 from '../../../Image/QA-services/Frame 1300193285.svg'

function DevelopmentProcess() {
  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row m-0">
          <div className="development-process">
            <div className="col-10 p-0">
              <div className="heading">Our Quality Assurance Process</div>
              <div className="row mx-0 my-4 pt-4">
                <div className="col-lg-5 col-sm-9 pb-lg-0 pb-4 col-12 p-0 mb-lg-0 mb-5 order-lg-1 order-2">
                  <div className="development-textbox">
                    <div className="label p-0">Requirement Analysis</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Thoroughly Analyze Software Requirements.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Identify Functional & Non-Functional Specifications.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Collaborate With Stakeholders To Clarify Expectations & Objectives.</div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 col-12 p-0 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">1</div></div>
                  </div>
                  <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                </div>
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 pb-lg-5 pb-4 mb-2 order-3" style={{marginBottom:"160px"}}>
                  <div className="development-imgbox">
                    <img src={dev} alt="" />
                  </div>
                </div>
              </div>
              <div className="row mt-4 pt- mx-0">
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 mb-5 order-lg-1 order-3"  style={{marginBottom:"140px"}}>
                  <div className="development-imgbox">
                    <img src={dev1} alt="" />
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">2</div></div>
                  </div>
                  <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                </div>
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-3 order-2">
                  <div className="development-textbox">
                    <div className="label">Test Planning</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Define Test Objectives, Scope, & Strategies.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Develop A Comprehensive Test Plan & Schedule</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Identify Test Deliverables & Resource Requirements.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 mt-4 pt-4">
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-1 order-2">
                  <div className="development-textbox">
                    <div className="label p-0">Test Case Design</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Create Detailed Test Cases Based Requirements.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Cover Different Functional Scenarios & Edge Cases.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Incorporate Positive & Negative Test Scenarios.</div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">3</div></div>
                  </div>
                  <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                </div>
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 order-3" style={{marginBottom:"100px"}}>
                  <div className="development-imgbox">
                    <img src={dev4} alt="" />
                  </div>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 mb-5 order-lg-1 order-3"  style={{marginBottom:"160px"}}>
                  <div className="development-imgbox">
                    <img src={dev3} alt="" />
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">4</div></div>
                  </div>
                  <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                </div>
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-3 order-2">
                  <div className="development-textbox">
                    <div className="label">Test Execution</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Execute Test Cases According To Test Plan.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Verify Results Against Expected Results.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Record & Report Defects Found</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0 pt-3 mt-4">
                <div className="col-lg-5 col-sm-9 col-12 mb-lg-0 mb-5 p-0 pb-lg-0 pb-4 order-lg-1 order-2">
                  <div className="development-textbox">
                    <div className="label p-0">Test Reporting and Metrics</div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Generate Comprehensive Test Reports.</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Capture Key Metrics (Test Coverage, Defect Density, & Test Execution Status).</div>
                    </div>
                    <div className='d-flex'>
                      <span className='label-point-dot'><GoDot /></span>
                      <div className="label-points p-0">Communicate Test Results & Findings To Stakeholders.</div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 col-12 me-lg-0 me-sm-4 me-0 mb-sm-0 mb-5 p-0 text-center order-lg-2 order-1">
                  <div className="step-box">
                    <div className="step"> <div className="step-num">5</div></div>
                  </div>
                  {/* <div className="line"><div className="step-line"></div></div> */}
                </div>
                <div className="col-lg-5 col-12 text-lg-start text-end px-0 order-3">
                  <div className="development-imgbox">
                    <img src={dev2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DevelopmentProcess
