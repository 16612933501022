import React from 'react';
import img from '../../Image/Group 143725873.png';
import img1 from '../../Image/Vector (9).png';
import img2 from '../../Image/Group 143725912 (2).png';
import img3 from '../../Image/Group 143725911.png';
import img4 from '../../Image/Group 143725914 (1).png';
import img5 from '../../Image/Group 143725917.png';
import { GoArrowRight, GoDot } from 'react-icons/go';
import { Link } from 'react-router-dom';

function Services() {
    let data=[
        {
            img: img1,
            id_name:'card dev_card',
            title: "Development Services",
            link:"development-services",
            description: "We will develop a winning website using the best UI/UX practices to get maximum conversions from traffic.",
            keyServices: ["Web Development", "Mobile App Development", "Custom Software Development", "E-commerce Platform..."]
        },
        {
            img: img,
            id_name:'card design-card',
            title: "Digital Design Services",
            link:"design-services",
            description: "Our designs are the essence of what our customers are trying to deliver. We pay time and energy to build rare designs that distinctively symbolize your brand.",
            keyServices: ["Product Design", "UI/UX Design", "Mobile App Design", "Brand Design"]
        },
      
        {
            img: img2,
            id_name:'card seo_card',
            title: "SEO & Digital Marketing",
            link:"seo-services",
            description: "As the best of digital advertising agencies should, we bring you flocks of inbound prospects.",
            keyServices: ["Search Engine Optimization", "Social Media Marketing", "Email Marketing", "Content Strategy & Optimization"]
        },
        {
            img: img3,
            id_name:'ecommerce-card',
            title: "E-commerce & Marketplace Solutions ",
            link:"ecommerce-services",
            description: "End-to-end e-commerce development for online stores, including specialized services for Amazon and eBay.",
            keyServices: ["E-commerce Platform...", "Amazon & eBay Solutions", "Shopify Development", "Market Place Development"]
        },
        {
            img: img4,
            id_name:'new-card',
            title: "Cloud & DevOps Services",
            link:"cloud-services",
            description: "Assisting businesses in transitioning their infrastructure to the cloud and managing ongoing operations.",
            keyServices: ["Cloud Migration & Management", "DevOps Services"]
        },
        {
            img: img5,
            id_name:'new-card new-card-1',
            title: "QA & Consulting, Support Services",
            link:"quailty-services",
            description: "Strategic advisory services to optimize IT infrastructure and operations.",
            keyServices: ["QA Testing & Automation", "Digital Transformation", "IT Consulting Services", "Maintenance & Support Services"]
        }
    ]
    return (
        <div id='service' className='container-fluid p-0'>
            <div className='services-heading'>
                <div className='main_service_data'>
                <h3 style={{marginLeft:'5px'}}>Comprehensive Services <br /> for Every Challenge</h3>
                <p style={{marginLeft:'6px'}}>Innovate, Develop, Deploy - Nasksoft supports you at every stage with adaptable services, revving your journey to success with precision.</p>
           
            <div className='services-card'>
                <div className='row m-0'>
                    {data.map((service, index) => (
                        <div className='col-lg-4 col-md-6 mb-4 pe-sm-4 pe-0 ps-0' key={index}>
                            <Link to={`/${service?.link}`}>
                            <div className='second-card'>
                                <div className='first-card' id={`${service?.id_name}`}>
                                    <div className='f-c'>
                                        <div className='mobile-img'>
                                        <img src={service.img} alt='' />
                                        </div>
                                        <h4>{service.title}</h4>
                                        <p>{service.description}</p>
                                        <h5>Key Services</h5>
                                        {service.keyServices.map((keyService, keyIndex) => (
                                            <span className='d-flex align-items-center' key={keyIndex}>
                                                <GoDot />
                                                <h6 style={{ marginTop: '10px' }}>{keyService}</h6>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className='ser-button text-center'>
                                    <button><GoArrowRight /></button>
                                    <p>Learn More</p>
                                </div>
                            </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
                </div>
        </div>
    );
}

export default Services;
