import React, { useEffect, useState } from 'react';
import img2 from '../../Image/5b1a07e4a81b1-300x170.jpg';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

function Latestnews() {
    const [newsData, setNewsData] = useState([]);
    const [newsData1, setNewsData1] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(6)
    const itemsPerPage = 15; // Show 6 items per page

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setActivePage(pageNumber);
        }
    };

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(`https://nasksoft.com/nasksoft/public/api/blogs-data`);
                const data = await response.json();
                const formattedData = data?.blog?.map((article, index) => ({
                    id: index + 1,  // Generate a unique ID based on the index
                    title: article.heading,
                    heading: article.topic,
                    link: article.video_url,
                    author: article.author || "Unknown",  // Fallback if author is not available
                    date: new Date(article.created_at).toLocaleDateString(),  // Format date
                    img: article.thumbnail_img || img2,  // Fallback image if not available
                    description: article.description || "No description available." // Fallback description
                }));
                setNewsData(formattedData);
                setTotalPages(formattedData?.length / itemsPerPage)
                // console.log(formattedData?.lenth/itemsPerPage);

                setNewsData1(formattedData.slice(16, 30)); // Assuming these are for the trending headlines
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchNews();
    }, []);

    // Paginate the news data for the current page
    const paginatedNewsData = newsData.slice(
        (activePage - 1) * itemsPerPage,
        activePage * itemsPerPage
    );

    return (
        <>
            <div className='latest-news px-4'>
                <div className='row m-0'>
                    <div className='col-lg-8 pe-4'>
                        <div className='latest-card'>
                            <div className='latest-card1'>
                                <div className='row'>
                                    {paginatedNewsData?.map((item) => {
                                        return (
                                            <a href={item?.link} target='_blank' rel="noreferrer" style={{ textDecoration: "none" }} className='col-lg-6 p-0 mb-3' key={item.id}>
                                                <div>
                                                    <div className='box'>
                                                        <div style={{ position: 'relative' }} className='nsh'>
                                                            <img style={{ width: "100%", borderRadius: '8px', height: "250px", objectFit: "cover" }} src={"https://nasksoft.com/nasksoft/public/" + item.img} alt='...' />
                                                            <div style={{ position: 'absolute', top: "157px", paddingRight: '40px' }} className='ns-img'>
                                                                <div style={{ marginLeft: '10px', marginTop: '5px' }} className='ns-h'>
                                                                    <h3 style={{
                                                                        fontSize: '20px',
                                                                        fontWeight: '500',
                                                                        fontFamily: 'var(--font-family)',
                                                                        color: "white",
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 2,
                                                                        WebkitBoxOrient: 'vertical',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis'
                                                                    }}>
                                                                        {item?.title}
                                                                    </h3>

                                                                </div>
                                                                <div style={{ marginLeft: '10px', marginTop: '20px' }} className='d-flex'>
                                                                    <h4 style={{ marginRight: '12px' }}>By {item?.author}</h4>
                                                                    <h4>{item?.date}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='my-news d-flex p-0'>
                                                            <div className='late-new'>
                                                                <div className='col-lg-12 p-0'>
                                                                    <div className='late-heading'>
                                                                        <h3>{item?.title}</h3>
                                                                        <div className='d-flex mt-3'>
                                                                            <h4 style={{ marginRight: '12px' }}>By {item?.author}</h4>
                                                                            <h4>{item?.date}</h4>
                                                                        </div>
                                                                        <hr className='mt-0 mb-2' style={{ color: '#727272' }} />
                                                                        <div className='dec mt-1' dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-4 ps-4'>
                        <div className='side-latest-card'>
                            <h3 style={{ marginBottom: '35px' }} className='px-3'>Trending Headlines</h3>
                           
                           {newsData1?.map((item, index) => (
                            <a href={item?.link} style={{textDecoration:'none'}}>
                                <div className='mandorty d-flex flex-wrap' key={index}>
                                    <div style={{ width: "35%", height: "100px" }} className='side-latest'>
                                        <img style={{ width: "100%", height: "100%" }} src={"https://nasksoft.com/nasksoft/public/" + item.img} alt='...' />
                                    </div>
                                    <div style={{ width: "65%" }} className='m-tags'>
                                        <div className='side-late-heading'>
                                            <h3>{item?.heading}</h3>
                                            <h4>{item?.title}</h4>
                                            <div className='d-flex'>
                                                <h4 style={{ marginRight: '35px' }}>By {item?.author}</h4>
                                                <h4>{item?.date}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </a>
                            ))}   
                        </div>
                    </div>

                    {/* Pagination Controls */}
                    <div className="col-8 p-0">
                        <div className="pagination-btn Careers-Data">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination d-flex justify-content-center">
                                    <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
                                        <a className="page-link" href="..." onClick={() => handlePageChange(activePage - 1)} aria-label="Previous">
                                            <span aria-hidden="true"><MdKeyboardArrowLeft /></span>
                                        </a>
                                    </li>
                                    {Array.from({ length: totalPages }, (_, index) => index + 1).map((number) => (
                                        <li className="page-item" key={number}>
                                            <a className={`page-link ${activePage === number ? 'active' : ''}`} href="..." onClick={() => handlePageChange(number)}>
                                                {number}
                                            </a>
                                        </li>
                                    ))}
                                    <li className={`page-item ${activePage === totalPages ? 'disabled' : ''}`}>
                                        <a className="page-link" href="..." onClick={() => handlePageChange(activePage + 1)} aria-label="Next">
                                            <span aria-hidden="true"><MdKeyboardArrowRight /></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Latestnews;
