import React from 'react';
import img from '../../src/Image/Group 143725884.png';
import img1 from '../../src/Image/Group 143725885 (2).png';
import img2 from '../../src/Image/Group (1).png';
import img3 from '../../src/Image/Group 143725893.png';
import img4 from '../../src/Image/Frame 626332.png';
import img5 from '../../src/Image/Group 427318909.png';

function Industries() {
    const industries = [
        {
            img: img,
            title: 'B2B / Financial Services',
            description: 'With the integration of platform development and customer engagement, your brand will secure success. Engage and notice how well we employ Digital Potential.',

        },
        {
            img: img1,
            title: 'B2C / Consumer Products',
            description: 'Latest digital strategies help brands committed to continuous learning and social feedback connect with customers, enhance user experiences, and boost sales.',
        },
        {
            img: img2,
            title: 'Start-Ups & Kickstarters',
            description: 'We empower businesses with entrepreneurial values with agile development, enabling them to launch, grow, and scale that requires distinctive agency approaches.',
        },
        {
            img: img3,
            title: 'Real Estate & Construction',
            description: 'We deliver property management systems, real estate listing platforms, virtual tours, CRM tools for real estate agents, and AI-driven property analysis.',
        },
        {
            img: img4,
            title: 'Beauty & Fashion Industry',
            description: 'We engage beauty and fashion brands with their audience using influencer marketing, e-commerce tools, and engaging video content through a mobile-first, social-driven strategy.',
        },
        {
            img: img5,
            title: 'Food & Beverage Industry',
            description: 'Nasksoft aids the food & beverage industry by developing e-commerce platforms, and inventory systems, enhancing operations and customer engagement.',
        },
    ];

    return (
        <div className='Industries'>
            <div className='container-fluid'>
                <div className='indus'>
                    <h3>Industries of Focus</h3>
                    <p>We drive digital transformation across industries, helping businesses reach their full potential with human-centred, innovative digital solutions.</p>
                    <div className='row my-5 pe-1'>
                        {industries.map((industry, index) => (
                            <div key={index} className='col-lg-4 col-md-6 mb-4 pe-sm-5'>
                                <div className='indus-card'>
                                   <div className='d-flex justify-content-sm-start justify-content-center'> <img style={{height:"50px"}} src={industry.img} alt='...' className='img-fluid' /></div>
                                    <h3>{industry.title}</h3>
                                    <p>{industry.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Industries;
