import React from 'react'
import img from '../../Image/about.mp4'
import img1 from '../../Image/poster/about-us.jpg'
function OurMisson() {
    return (
        <div className='main_missinn_about p-5'>
            <div className='row'>
                <div className='col-lg-6 col-md-12 col-12 my-lg-5 mt-5 mb-0' data-aos="fade-down">
                    <div>
                        <div className='d-flex'>
                            <h3>Our Mission</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="37" viewBox="0 0 25 37" fill="none">
                                <path d="M12.4999 8.83594C10.2033 8.83594 8.33398 10.7054 8.33398 13.0041C8.33398 15.3029 10.2034 17.1723 12.4999 17.1723C14.7963 17.1723 16.6681 15.3029 16.6681 13.0041C16.6681 10.7056 14.7964 8.83594 12.4999 8.83594Z" fill="white" />
                                <path d="M25 13.0048L22.418 10.3477L23.3276 6.75252L19.7612 5.74134L18.7503 2.17496L15.1593 3.08199L12.4997 0.5L9.84266 3.08199L6.25201 2.17496L5.23881 5.74134L1.6747 6.75252L2.584 10.3477L0 13.0048L2.584 15.6641L1.6747 19.2593L5.23881 20.2682L6.25201 23.8346L9.84266 22.9297L12.4997 25.5096L15.1591 22.9297L18.75 23.8346L19.7612 20.2682L23.3276 19.2593L22.4183 15.6641L25 13.0048ZM12.4995 19.9545C8.6699 19.9545 5.55691 16.837 5.55691 13.0048C5.55691 9.17268 8.66964 6.05769 12.4995 6.05769C16.3291 6.05769 19.4446 9.17268 19.4446 13.0048C19.4446 16.8366 16.3294 19.9545 12.4995 19.9545Z" fill="white" />
                                <path d="M14.4358 27.5064L12.4999 29.3867L10.5663 27.5064L9.01976 26.0039L8.33398 26.1766V36.75L12.4999 32.814L16.6681 36.75V26.1766L15.98 26.0039L14.4358 27.5064Z" fill="white" />
                            </svg>
                        </div>
                        <p>Our focus mission is to determine and implement the right solutions with precision, ensuring a customer-centric approach and a relentless commitment to quality. By prioritizing integrity, exceptional service, and the highest standards of quality, we strive to be a trusted partner in delivering measurable, outcome-driven results. Our goal is to support businesses in achieving sustainable success through advanced, efficient, and scalable technology solutions.</p>
                    </div>
                    <div className='mt-5'>
                        <div  className='d-flex'>
                            <h3>Our Vision</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M26.8866 20.9207C29.066 22.0414 30.4843 23.7494 31.5515 25.5111C31.8368 24.7957 31.9981 24.0389 31.9939 23.2657C32.0021 20.3792 30.0669 17.8482 26.8825 16.3431C26.9487 17.7985 26.9446 19.3163 26.8867 20.9209L26.8866 20.9207Z" fill="white" />
                                <path d="M12.7558 20.9325C12.6565 19.3361 12.6359 17.8267 12.6938 16.3958C9.53019 17.9302 7.58662 20.4774 7.57812 23.3475C7.57395 24.1207 7.7352 24.8777 8.01659 25.5889C8.91387 23.6909 10.5103 22.0533 12.7558 20.9325L12.7558 20.9325Z" fill="white" />
                                <path d="M16.1724 24.7947C16.1682 25.2206 16.6977 25.5681 17.3469 25.5639L22.2391 25.5433C22.8841 25.5433 23.4012 25.2002 23.4012 24.7781L23.4054 22.8551L16.1767 22.8799L16.1724 24.7947Z" fill="white" />
                                <path d="M12.4225 29.6211C12.4225 31.0127 14.128 33.9269 14.128 33.9269C14.128 33.9269 15.8337 30.7901 15.8376 29.6093C15.8376 28.6632 15.0743 27.9076 14.1281 27.9078C13.1859 27.9116 12.4225 28.6827 12.4225 29.6211L12.4225 29.6211Z" fill="white" />
                                <path d="M23.837 29.6479C23.8331 31.0355 25.5425 33.9536 25.5425 33.9536C25.5425 33.9536 27.2521 30.8127 27.248 29.632C27.2519 28.6898 26.4886 27.9265 25.5464 27.9304C24.6005 27.9384 23.8371 28.7019 23.837 29.6479L23.837 29.6479Z" fill="white" />
                                <path d="M19.8372 26.6264C18.8949 26.6302 18.1315 27.3937 18.1276 28.336C18.1276 29.7275 19.8331 32.6417 19.8331 32.6417C19.8331 32.6417 21.5468 29.5048 21.5427 28.3241C21.5467 27.382 20.7794 26.6225 19.8372 26.6264L19.8372 26.6264Z" fill="white" />
                                <path d="M18.3949 1.43875C17.8796 2.16468 17.2764 3.1394 16.6767 4.32125C17.2078 4.44298 19.6177 6.22901 22.8132 4.31434L22.8657 4.22665C22.2836 3.0976 21.712 2.16138 21.2141 1.45293C20.5304 0.474448 19.0858 0.467549 18.3949 1.43878L18.3949 1.43875Z" fill="white" />
                                <path d="M16.2837 5.12983C13.6991 10.568 13.2608 15.7043 13.6658 21.4936C13.6866 21.7872 14.0175 22.0105 14.4102 22.0147L25.2531 21.9733C25.6501 21.9733 25.9809 21.7417 25.9934 21.448C26.2084 15.5427 25.9646 10.6915 23.2725 5.05513L23.2642 5.06335C19.6619 7.21935 16.9102 5.29412 16.2835 5.12955L16.2837 5.12983ZM21.2133 18.2225C21.2131 19.0057 20.5773 19.6432 19.7935 19.6467C19.0099 19.65 18.3748 19.0178 18.3753 18.2348C18.3755 17.4516 19.0114 16.8141 19.795 16.8108C20.5788 16.8072 21.2138 17.4395 21.2133 18.2225L21.2133 18.2225ZM21.2142 13.46C21.214 14.2432 20.5781 14.8807 19.7944 14.8841C19.0108 14.8875 18.3757 14.2553 18.3762 13.4723C18.3764 12.6891 19.0122 12.0516 19.7958 12.0483C20.5796 12.0448 21.2145 12.6768 21.2142 13.46L21.2142 13.46ZM21.215 8.6973C21.2147 9.48048 20.5789 10.118 19.7951 10.1215C19.0115 10.1248 18.3765 9.49258 18.3769 8.70958C18.3772 7.9264 19.013 7.28888 19.7966 7.28557C20.5803 7.2821 21.2154 7.9143 21.215 8.69731L21.215 8.6973Z" fill="white" />
                            </svg>
                        </div>
                        <p>Becoming a leading digital service provider in the IT and software development industry, offering comprehensive solutions for innovative software applications. At Nasksoft, we draw strength from the talents, creativity, and experiences of our team, who are central to our success. We believe that enabling an inclusive workplace, where every employee is valued and empowered to reach their full potential, directly contributes to achieving business success and delivering excellence to our clients.</p>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 col-12 py-4'>
                    <div className='about_video'>
                    <video poster={img1} autoPlay={true} muted loop >
              <source src={img} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          <div className='over_lay'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurMisson