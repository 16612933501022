import React from 'react';
import analytic from '../../../Image/Seo-services/Frame 1300193291 (1).png';
import playspeed from '../../../Image/Seo-services/Frame 1300193290.png';
import playspeed_insight from '../../../Image/Seo-services/Frame 1300193289.png';
import trend from '../../../Image/Seo-services/Frame 1300193288.png';
import mrush from '../../../Image/Seo-services/Frame 1300193292 (1).png';


const tools = [
    { id: 1, name: 'Google Analytics 4', image: analytic },
    { id: 2, name: 'Google Page Speed Insights', image: playspeed },
    { id: 3, name: 'Google Page Speed Insights', image: playspeed_insight },
    { id: 4, name: 'Google Trends', image: trend },
    { id: 5, name: 'SEMrush', image: mrush }, 
];


function OurTools() {
    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="our-tools">
                    <div className="row m-0 p-0">
                        <div className="col-xl-4 col-lg-5 col-12 p-0">
                            <div className="tool-text new-tool-text">
                                <div className="heading text-lg-start text-center">
                                Technologies & Tools Stack For Powering Development
                                </div>
                                <div className="tool-para text-lg-start text-center">
                                Collaborate with Nasksoft’s IT experts to create exceptional custom software 
                                using advanced frameworks and technologies.
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-12 ps-md-5 pe-lg-0 px-4 pe-md-5">
                            <div className="row px-3 mx-0 mt-4 pt-4 d-flex justify-content-lg-between justify-content-center">
                                {tools.map((tool) => (
                                    <div key={tool.id} className="col-lg-2 col-3 col-sm-4 col-6 px-lg-0 p-sm-4 p-3 pt-lg-3">
                                        <div className="tools-iconbox">
                                            <div className="icon">
                                                <span className="icon-img-1">
                                                    <img src={tool.image} alt={tool.name} />
                                                </span>
                                                <div className="icon-name-1">{tool.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OurTools;
