import React, { useEffect } from 'react';
import { MdArrowOutward } from 'react-icons/md';
import video from '../../../Image/QA_video.mp4';
import img from '../../../Image/poster/qa-service.jpg'

function Details() {
    useEffect(() => {
        const animatedRow = document.querySelector('.animation-row');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('scrolled');
                }
            });
        }, {
            threshold: 0.5
        });

        if (animatedRow) {
            observer.observe(animatedRow);
        }
        return () => {
            if (animatedRow) {
                observer.unobserve(animatedRow);
            }
        };
    }, []);

    return (
        <>
            <div className="container-fluid px-0 mx-0 mt-3 pt-2">
                <div className="development-services">
                    <div className="details-parabox">
                        <p className='details-para mb-4'>
                        Nasksoft offers effective QA, consulting & support services that assure your product meets the highest standards of quality and performance. Our expert QA team utilises advanced testing technologies to guarantee steady product functionality across all environments. We help businesses provide outstanding user experiences through our quality assurance processes while consulting services provide customised solutions to optimize your product's performance. At Nasksoft, we ensure seamless operations and long-term success for your business, focusing on stability, reliability, and customer satisfaction.
                        </p>
                        {/* <p className='details-para'>
                            We collaborate with you to develop strong, purpose-built Web / Mobile Applications that are user-centered, mobile-friendly, and extremely intuitive using our 360-degree approach for Products. We can assist you in developing robust dynamic Web / Mobile Applications that work well for both users and search engines. We collaborate with you to develop strong, purpose-built Web / Mobile Applications that are user-centered, mobile-friendly, and extremely intuitive using our 360-degree approach for Products. We can assist you in developing robust dynamic Web / Mobile Applications that work well for both users and search engines.
                        </p> */}
                    </div>


                    <div className="row mx-0 mt-5 pt-2 animation-row">
                        <div className="col-lg-7 col-12 px-0 pb-md-0 pb-4">
                            <div className="services-imgbox">
                                <video poster={img} autoPlay={true} muted loop id="bgVideo">
                                    <source src={video} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                </video>
                                <div className="frame"></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 mt-lg-0 mt-lg-0 mt-sm-5 mt-4">
                            <div className="services-optionbox">
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Test Planning</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Test Case Development</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Test Execution</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Defect Reporting</div>
                                <div className="services-option"><span className='option-arrow'><MdArrowOutward /></span>Regression Testing</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Details;
